import { Spinner, Link } from 'components/Elements';
import { useContracts } from '../api/getContracts';
import { Eye, ChevronRight } from 'react-feather'; // Assuming ChevronRight icon is available
import { useRef, useState } from 'react';
import { useAuth } from 'lib/auth';

export const ContractLists = () => {
  const contractsQuery = useContracts();
  const inputRef = useRef(null);
  const [q, setQ] = useState('');

  const { user } = useAuth();
  const [searchColumns, setSearchColumns] = useState([
    'lender.basicInformation.firstName',
    'lender.basicInformation.lastName',
    'lender.basicInformation.contactInformation.email',
    'lender.basicInformation.contactInformation.phoneNumber',
    'borrower.basicInformation.firstName',
    'borrower.basicInformation.lastName',
    'borrower.basicInformation.contactInformation.email',
    'borrower.basicInformation.contactInformation.phoneNumber',
    'loan.tracking_id',
    'contract_date',
  ]);

  if (contractsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!contractsQuery.data) return null;

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some((column) => {
        const value = extractNestedValue(row, column);
        return value.toString().toLowerCase().includes(q.toLowerCase());
      })
    );
  }

  const extractNestedValue = (obj, path) => {
    const keys = path.split('.');
    return keys.reduce((acc, key) => (acc && acc[key] ? acc[key] : ''), obj);
  };

  let contracts = search(contractsQuery.data);

  const handleInputChange = (event) => {
    setQ(event.target.value);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {contracts.map((contract) => (
        <Link
          key={contract._id}
          to={`/app/contracts/${contract._id}`}
          className="block rounded-lg overflow-hidden shadow-md bg-white hover:shadow-lg transition duration-300"
        >
          <div className="p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-sm text-gray-500 font-bold">{contract.status}</div>
              <ChevronRight className="text-gray-500 h-5 w-5" />
            </div>

            {user.role === 'LENDER' && (
              <div className="mb-4">
                <div className="border-b text-sm pb-2 mb-2 text-primary-100">Borrower</div>
                <div className="flex items-center">
                  <div className="w-10 h-10 rounded-full bg-primary-100 flex items-center text-white justify-center">
                    {contract.borrower.authentication?.username[0].toUpperCase()}
                  </div>
                  <div className="ml-2">
                    <div className="font-bold text-primary-100">
                      {contract.borrower.authentication?.username}
                    </div>
                    <div className="text-xs text-gray-500">
                      {contract.borrower.basicInformation?.contactInformation?.email}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {user.role === 'BORROWER' && (
              <div className="mb-4">
                <div className="border-b text-sm pb-2 mb-2 text-secondary-100">Lender</div>
                <div className="flex items-center">
                  <div className="w-10 h-10 rounded-full bg-secondary-100 flex items-center text-white justify-center">
                    {contract.lender.authentication?.username[0].toUpperCase()}
                  </div>
                  <div className="ml-2">
                    <div className="font-bold text-secondary-100">
                      {contract.lender.authentication?.username}
                    </div>
                    <div className="text-xs text-gray-500">
                      {contract.lender.basicInformation?.contactInformation?.email}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Link>
      ))}
    </div>
  );
};
