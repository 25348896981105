import { useEffect } from 'react';

export const useUserActivity = (onActive) => {
  useEffect(() => {
    const handleActivity = () => {
      onActive();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('visibilitychange', handleActivity);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('visibilitychange', handleActivity);
    };
  }, [onActive]);
};
