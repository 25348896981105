import React, { useState, useEffect } from 'react';
import * as z from 'zod';
import { Form, InputField, InputFieldWithInfo, ReactNumberFormatInputField, ReactNumberFormatInputField2, TextAreaFieldWithInfo } from 'components/Form';
import { ReactDateFieldWithInfo, ReactSelectField, ReactSelectField2, ReactSelectFieldWithInfo } from 'components/Pigeon';
import { Borrow } from '../../tips/Borrow';

import { useProducts } from 'features/products';
import { PaymentSchedule } from '../PaymentSchedule';
import { Button, Spinner } from 'components/Elements';
import storage from 'utils/storage';
import { ComputeNetYouReceive } from '../ComputeNetToRecieve';

const valuationOptions = [
  { value: 80000, label: 'Bikes' },
  { value: 400000, label: 'Commercial Heavy Vehicles' },
  { value: 200000, label: 'Commercial Light Vehicles' },
  { value: 120000, label: 'Personal Vehicles' },
];
const trackerOptions = [
  { value: 80000, label: 'Hire Tracker (Ugx 80,000)' },
  { value: 400000, label: 'Buy Tracker (Ugx 400,000)' },
];
const schema = z.object({
  collateralCategory: z.string({
    required_error: "collateral Category is required",
    invalid_type_error: "collateral Category must be a string",
  }),
  product: z.string({
    required_error: "Product is required",
    invalid_type_error: "Product must be a string",
  }),
  purpose: z.string({
    required_error: "Purpose is required",
    invalid_type_error: "Purpose must be a string",
  }),
  alternativeSourcesOfIncome: z.string({
    invalid_type_error: "alternative Sources Of Income must be a string",
  }).optional(),
  anticipated_disbursement_date: z.string({
    invalid_type_error: "anticipated disbursement date must be a string",
  }).optional(),
  amount: z.number({
    required_error: "Amount is required",
    invalid_type_error: "Amount must be a number",
  }),
  interestRate: z.number({
    required_error: "interest Rate is required",
    invalid_type_error: "interest Rate must be a number",
  }),
  period: z.number({
    required_error: "loan Term is required",
    invalid_type_error: "Loan Term must be a number",
  }),
  vehicleType: z.object({
    value: z.string(),
    label: z.number(),
  }).optional(),
  trackerType: z.object({
    value: z.string(),
    label: z.number(),
  }).optional(),
  monthlyPayment: z.number().optional(),
});

export const LoanDetails = ({ nextStep, prevStep }) => {
  const [interestRate, setInterestRate] = useState(0);
  const [serviceFeePercentage, setServiceFeePercentage] = useState();
  const [serviceFee, setServiceFee] = useState();
  const [amount, setAmount] = useState();
  const [assessmentFee, setAssessmentFee] = useState();
  const [acceptMonthlyPayment, setAcceptMonthlyPayment] = useState();
  const [term, setTerm] = useState();
  const [averageLendingRate, setAverageLendingRate] = useState();
  const [collateralCategory, setCollateralCategory] = useState();
  const [purpose, setPurpose] = useState();
  const [monthlyPayment, setMonthlyPayment] = useState();
  const [maxMonthlyPayment, setMaxMonthlyPayment] = useState();
  const [product, setProduct] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [alternativeSourcesOfIncome, setAlternativeSourcesOfIncome] = useState()
  const productsQuery = useProducts();
  const [disbursmentDate, setDisbursmentDate] = useState()
  const [productErr, setProductErr] = useState();
  const [selectProduct, setSelectProduct] = useState()
  const [selectVehicle, setSelectVehicle] = useState()
  const [selectTracker, setSelectTracker] = useState()
  const [showVehicleData, setShowVehicleData] = useState(false)
  const [ammountErr, setAmmountErr] = useState()
  const [wrongAmountErr, setWrongAmountErr] = useState()
  const [termErr, setTermErr] = useState()
  const [acceptMonthlyPaymentErr, setAcceptMonthlyPaymentErr] = useState()
  const [paybackAmountErr, setPaybackAmountErr] = useState()
  const [paymentMethod, setPaymentMethod] = useState()
  const [loading, setLoading] = useState(true);
  const [vehicleDataErr, setVehicleDataErr] = useState();

  useEffect(() => {
    //console.log('loans details loaded', paymentMethod);
    const getData = async () => {
      setLoading(true);
      // localStorage.removeItem('msw-dbformValues')
      // localStorage.setItem('pigeonLoansCurrentStep', '1')
      const data = storage.getFormValues()
      setPaymentMethod(data.paymentMethod)
      console.log('loans details loaded', data);
      if (data.collateralCategory) {
        setCollateralCategory(data.collateralCategory);
        if (data.collateralCategory.toLowerCase() === 'vehicles') {
          const sv = localStorage.getItem('vehicleType')
          if (sv) {
            setSelectVehicle(sv)
          }
          const st = localStorage.getItem('trackerType')
          if (st) {
            setSelectTracker(st)
          }
          setShowVehicleData(true);
        } else {
          setShowVehicleData(false);
        }
        
      }
      if (data.product) {
        if (data.collateralCategory) {
          setSelectProduct(localStorage.getItem('selectedProduct'))
          setAverageLendingRate(data.product?.average_lending_rate);
          const totalAssessmentFees = computeTotalAssessmentFees(
            data.collateralCategory.toLowerCase(), data.product
          );
          setAssessmentFee(totalAssessmentFees);
          setProduct(data.product);
        }
      }
      if (data.alternativeSourcesOfIncome) {
        console.log('alternativeSourcesOfIncome', data.alternativeSourcesOfIncome)
        setAlternativeSourcesOfIncome(data.alternativeSourcesOfIncome)
        
      }
      if (data.anticipated_disbursement_date) {
        setDisbursmentDate(data.anticipated_disbursement_date)
         
      }
      if (data.purpose) {
        setPurpose(data.purpose)
      }
      if (data.amount) {
        try {
          let amt = parseInt(data.amount);
          setAmount(amt);
          
          // service fee
          if (data.product) {
            for (const fee of data.product.service_fees) {
              if (amt >= fee.range.min && amt <= fee.range.max) {
                setServiceFeePercentage(fee.percentage);
                setServiceFee((fee.percentage / 100) * amt);
                break;
              }
            }
          }
          // Recalculate max monthly payment
          if (data.paymentMethod !== 'INTEREST_ONLY') {
            // Recalculate max monthly payment
            if (data.interestRate) {
              const maxPaymt = (amt * data.interestRate) / 100;
              setMaxMonthlyPayment(maxPaymt);
            }
          }
        } catch (error) {
          //console.log('amount is not a number')
        }
      }
      if (data.interestRate) {
        let floatValue = parseFloat(data.interestRate);
        setAmmountErr()
        // Recalculate max monthly payment
        if (data.amount) {
          setMaxMonthlyPayment((data.amount * floatValue) / 100);
        }
        setInterestRate(floatValue);
      }
      if (data.period) {
        const tt = parseInt(data.period)
        setTerm(tt);
      }
      const mp = localStorage.getItem('acceptMonthlyPayment')
      if (mp) {
        setAcceptMonthlyPayment(mp)
      }
      if (data.monthlyPayment) {
        const floatValue = parseFloat(data.monthlyPayment);
        setMonthlyPayment(floatValue);
      }
      try {
         const schemaData = schema.safeParse({
            collateralCategory: data.collateralCategory,
            product: localStorage.getItem('selectedProduct'),
            alternativeSourcesOfIncome: data.alternativeSourcesOfIncome,
            anticipated_disbursement_date: data.anticipated_disbursement_date,
            purpose: data.purpose,
            amount: data.amount,
            interestRate: data.interestRate,
            period: data.period,
          });
          if(schemaData.success){
            //console.log('schemaData', schemaData.data)
          } else if(schemaData.error){
            //console.log('schemaData fail', schemaData.error)
          }
        } catch (err) {
          const formattedErrors = {};
          err.errors.forEach((error) => {
            formattedErrors[error.path[0]] = error.message;
          });
          console.log(' selectedProduct err', formattedErrors)
        }
      setLoading(false)
    }
    getData()
  }, []);


  if (productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  function computeTotalAssessmentFees(category, product) {
    let totalFees = 0;
    if (category === 'real_estate_with_land_title') {
      category = 'real_estate';
    }
    if (product?.applicable_fees?.hasOwnProperty(category)) {
      const fees = product?.applicable_fees[category];
      //console.log('fees', fees)
      if (category === 'computers') {
        totalFees = fees?.assessment_fees + fees?.tracker_fees;
      }
      if (category === 'other_electronics') {
        totalFees = fees.assessment_fees
      }
      if (category === 'real_estate') {
        let stamp_duty_fees = 0;
        if (fees.mortgage_placement?.stamp_duty_fees !== 0) {
          stamp_duty_fees = (fees.mortgage_placement?.stamp_duty_fees / 100) * amount
        }
        const mortgage_placement_fees =
          stamp_duty_fees +
          fees.mortgage_placement?.registration_fees +
          fees.mortgage_placement?.bank_charges +
          fees.mortgage_placement?.registrar_fees +
          fees.mortgage_placement?.legal_fees;
        const due_deligence_fees = fees.due_deligence_fee;
        totalFees = mortgage_placement_fees + due_deligence_fees;
      }
      if (category === 'smart_phone') {
        totalFees = fees.assessment_fees
      }
      if (category === 'television') {
        totalFees = fees?.assessment_fees + fees?.tracker_fees;
      }
      if (category === 'vehicles') {
        totalFees = selectVehicle?.value + selectTracker?.value + fees.caveat_placement_fee + fees.insurance_fee;
      }
    } else {
      // console.log('no totalfees calculated')
    }
    return totalFees;
  }

  if (!productsQuery.data) return null;

  return (
    <>
      {!loading && (
        <Form
          id="create-loan"
          onSubmit={async (values) => {
            const data = storage.getFormValues()
            if (!acceptMonthlyPayment) {
              setAcceptMonthlyPaymentErr(`Agree to pay UGX ${maxMonthlyPayment?.toLocaleString()} monthly or set your own amount to continue`)
              return
            }
            if (collateralCategory.toLowerCase() === 'vehicles') {
              if(!selectVehicle){
                setVehicleDataErr('Type of your Vehicle cannot be empty')
                return
              }
              if(!selectTracker){
                setVehicleDataErr('Vehicle Tracker cannot be empty')
                return
              }
            }
            console.log('onsubmit', data)
            nextStep();  
          }}
          options={{defaultValues: {
            collateralCategory: collateralCategory,
            product: selectProduct,
            alternativeSourcesOfIncome: alternativeSourcesOfIncome,
            anticipated_disbursement_date: disbursmentDate,
            purpose: purpose,
            amount: amount,
            interestRate: interestRate,
            period: term,
          }}}
          schema={schema}>
          {({ register, formState, watch, setValue }) => (
            <div className="px-12 md:px-24 pt-3">
              <div className="text-lg mb-6">Provide your loan details</div>
              <ReactSelectField
                label="Collateral Category *"
                //registration={register('collateralCategory')}
                type="text"
                editorContent={watch('collateralCategory')}
                setValue={setValue}
                name="collateralCategory"
                defaultValue={collateralCategory}
                error={formState.errors['collateralCategory']}
                options={[
                  { label: 'Real Estate', value: 'REAL_ESTATE_WITH_LAND_TITLE' },
                  { label: 'Vehicles', value: 'VEHICLES' },
                  { label: 'Computers', value: 'COMPUTERS' },
                  { label: 'Smart Phones', value: 'SMART_PHONES' },
                  { label: 'Television', value: 'TELEVISION' },
                  { label: 'Other Electronics', value: 'OTHER_ELECTRONICS' },
                ]}
                onChange={(e) => {
                  if (e) {
                    setProductErr()
                    formState.errors['collateralCategory'] = ''
                    setCollateralCategory(e.value);
                    if (e.value.toLowerCase() === 'vehicles') {
                      setShowVehicleData(true);
                    } else {
                      setShowVehicleData(false);
                    }
                    storage.setFormValues({
                      collateralCategory: e.value,
                    });
                  } else {
                    setShowVehicleData(false);
                  }
                }}
              />
              <br />
              {showVehicleData && (
                <>
                  <ReactSelectField2
                    label="Type of your Vehicle *"
                    type="text"
                    registration={register('vehicleType')}
                    name="vehicleType"
                    defaultValue={selectVehicle}
                    error={formState.errors['vehicleType']}
                    options={valuationOptions}
                    onChange={async (e) => {
                      if (e) {
                        setProductErr()
                        setVehicleDataErr()
                        setSelectVehicle(e)
                        localStorage.setItem('vehicleType', JSON.stringify(e))
                      } else {
                        setSelectVehicle();
                        localStorage.removeItem('vehicleType')
                      }
                    }}
                  />
                  <br />
                  <ReactSelectFieldWithInfo
                    label="Please Select Vehicle Tracker *"
                    type="text"
                    registration={register('trackerType')}
                    name="trackerType"
                    infoTitle="Vehicle Tracker"
                    infoDescription="The tracker will be used to keep track of the collateral asset's location during your loan term"
                    defaultValue={selectTracker}
                    error={formState.errors['trackerType']}
                    options={trackerOptions}
                    onChange={async (e) => {
                      if (e) {
                        setProductErr()
                        setVehicleDataErr()
                        setSelectTracker(e)
                        localStorage.setItem('trackerType', JSON.stringify(e))
                      } else {
                        setSelectTracker()
                        localStorage.removeItem('trackerType')
                      }
                    }}
                  />
                  {vehicleDataErr && (
                <div role="alert" aria-label={vehicleDataErr} className="text-sm font-semibold text-red-500">
                  {vehicleDataErr}
                </div>
              )}
                  <br />
                </>
              )}
              <ReactSelectField
                label="Please Select Product *"
                type="text"
                editorContent={watch('product')}
                setValue={setValue}
                registration={register('product')}
                name="product"
                defaultValue={selectProduct}
                error={formState.errors['product']}
                options={
                  Array.isArray(productsQuery.data)
                    ? productsQuery.data.map((p) => ({
                      label: p.product_title,
                      value: p._id,
                    }))
                    : []
                }
                onChange={async (e) => {
                  //console.log('product', e)
                  if (!collateralCategory) {
                    setProductErr('Please select a Collateral Category first')
                    return
                  }
                  if (e) {
                    if (collateralCategory.toLowerCase() === 'vehicles') {
                      if (!selectVehicle) {
                        setProductErr('The type of your vehicle cannot be empty')
                        return
                      }
                      if (!selectTracker) {
                        setProductErr('Please select the type of tracker above that we shall use to track your vehicle when you get the loan')
                        return
                      }
                    }
                    setProductErr('')
                    formState.errors['product'] = ''
                    setSelectProduct(e.value)
                    localStorage.setItem('selectedProduct', e.value)
                    const selectedProduct = await productsQuery.data?.find((p) => p._id === e.value);
                   // console.log('selectedProduct', selectedProduct)
                    setAverageLendingRate(selectedProduct?.average_lending_rate);
                    const totalAssessmentFees = computeTotalAssessmentFees(
                      collateralCategory.toLowerCase(), selectedProduct
                    );
                    setAssessmentFee(totalAssessmentFees);
                    //console.log('totalAssessmentFees', totalAssessmentFees)
                    setProduct(selectedProduct);
                    storage.setFormValues({
                      product: selectedProduct,
                    });
                  } else {
                    // console.log("outside e", e)
                  }

                }}
              />
              {productErr && (
                <div role="alert" aria-label={productErr} className="text-sm font-semibold text-red-500">
                  {productErr}
                </div>
              )}
              <TextAreaFieldWithInfo
                rows="4"
                label='What are you going to do with the money in detail? *'
                name="purpose"
                value={purpose}
                registration={register('purpose')}
                infoTitle="What are you going to do with the money in detail?"
                infoDescription="The purpose of the loan is to help you achieve your goals. Please provide a detailed explanation of what you
                intend to do with the money."
                error={formState.errors['purpose']}
                onChange={(event) => {
                  formState.errors['purpose'] = ''
                  try {
                    setValue('purpose', event.target.value);
                    setPurpose(event.target.value);
                    storage.setFormValues({
                      purpose: event.target.value,
                    });
                  } catch (err) {
                    console.log('err', err.message)

                    err.errors.forEach((error) => {
                      formState.errors[error.path[0]] = error.message;
                    });
                  }

                }}
              />
              <InputField
                type="text"
                label="Any alternative sources of Income?"
                name="alternativeSourcesOfIncome"
                value={alternativeSourcesOfIncome}
                error={formState.errors['alternativeSourcesOfIncome']}
                registration={register('alternativeSourcesOfIncome')}
                onChange={(event) => {
                  formState.errors['alternativeSourcesOfIncome'] = ''
                  setValue('alternativeSourcesOfIncome', event.target.value)
                  setAlternativeSourcesOfIncome(event.target.value);
                  storage.setFormValues({
                    alternativeSourcesOfIncome: event.target.value,
                  });
                }}
                className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
              <ReactDateFieldWithInfo
                label="Anticipated Disbursement date"
                name="anticipated_disbursement_date"
                infoTitle="Anticipated Disbursement date"
                infoDescription="This is the date when you wish to recieve the loan"
                value={disbursmentDate}
                error={formState.errors['anticipated_disbursement_date']}
                registration={register('anticipated_disbursement_date')}
                onChange={(event) => {
                  formState.errors['anticipated_disbursement_date'] = ''
                  setValue('anticipated_disbursement_date', event.target.value)
                  setDisbursmentDate(event.target.value);
                  storage.setFormValues({
                    anticipated_disbursement_date: event.target.value,
                  });
                }}
                className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />

              {product?.principal && (
                <>
                  <div className="">
                    <div className="flex flex-row items-center justify-between mt-3">
                      <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                        How much do you want to borrow? (UGX)
                      </div>
                      <Borrow
                        minAmount={product?.principal?.min}
                        maxAmount={product?.principal?.max}
                        bridge={product ? true : false}
                      />
                    </div>
                    <ReactNumberFormatInputField2
                      prefix="UGX "
                      name="amount"
                      value={amount}
                      error={formState.errors['amount']}
                      registration={register('amount')}
                      onChange={(event) => {
                        //console.log('amount', event);
                        formState.errors['amount'] = ''
                        setAmmountErr()
                        try {
                          let amt = parseInt(event.value);
                          setAmount(amt);
                          setValue('amount', amt)
                          setWrongAmountErr()
                          if (event.floatValue < product?.principal?.min) {
                            setWrongAmountErr('Amount must be greater than UGX ' + product?.principal?.min)
                          } else if (event.floatValue > product?.principal?.max) {
                            setWrongAmountErr('Amount must be less than UGX ' + product?.principal?.max)
                          }
                          storage.setFormValues({
                            amount: amt,
                          });
                          // service fee
                          if (product) {
                            for (const fee of product?.service_fees) {
                              if (amt >= fee.range.min && amt <= fee.range.max) {
                                //serviceFeePercentage = fee.percentage;
                                console.log('serviceFeePercentage', fee.percentage)
                                setServiceFeePercentage(fee.percentage);
                                setServiceFee((fee.percentage / 100) * amt);
                                break;
                              }
                            }

                          } else {
                            // set error message to select product first
                            setWrongAmountErr('Please select a product first')
                          }
                          // Recalculate max monthly payment
                          if (paymentMethod !== 'INTEREST_ONLY') {
                            // Recalculate max monthly payment
                            if (interestRate) {
                              const maxPaymt = (amt * interestRate) / 100;
                              console.log('maxPaymt', maxPaymt);
                              setMaxMonthlyPayment(maxPaymt);
                            }
                          }
                        } catch (error) {
                          // console.log('amount is not a number')
                        }

                      }}
                      min={parseInt(product?.principal?.min)}
                      max={parseInt(product?.principal?.max)}
                      className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                    {wrongAmountErr && (
                      <div role="alert" aria-label={wrongAmountErr} className="text-sm font-semibold text-red-500">
                        {wrongAmountErr}
                      </div>
                    )}
                  </div>
                  <InputField
                    type="number"
                    label="How much interest on the loan are you offering monthly? (%)"
                    name="interestRate"
                    value={parseFloat(interestRate)}
                    error={formState.errors['interestRate']}
                    registration={register('interestRate')}
                    onChange={(event) => {
                      // console.log('interestRate', event);
                      if(event){
                      formState.errors['interestRate'] = ''
                      let floatValue = parseFloat(event.target.value);
                      setAmmountErr()
                      // Recalculate max monthly payment
                      if (amount) {
                        console.log('interestRate set amount', amount)
                        setMaxMonthlyPayment((amount * floatValue) / 100);
                      } else {
                        setAmmountErr('Set an amount first')
                        setInterestRate()
                        return
                      }
                      // if (paymentMethod !== 'INTEREST_ONLY') {
                      //   if(amount){
                      //     console.log('interestRate set amount', amount)
                      //     setMaxMonthlyPayment((amount * floatValue) / 100);
                      //   } else {
                      //     console.log('interestRate not set amount', amount)
                      //     formState.errors['interestRate'] = 'Set an amount first'
                      //     setValue('interestRate', '')
                      //     return
                      //   }
                      // }
                      setInterestRate(floatValue);
                      setValue('interestRate', floatValue)
                      storage.setFormValues({
                        interestRate: floatValue,
                      });
                    }
                    }}
                    className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {ammountErr && (
                    <div role="alert" aria-label={ammountErr} className="text-sm font-semibold text-red-500">
                      {ammountErr}
                    </div>
                  )}
                </>
              )}

              {paymentMethod === 'INTEREST_ONLY' && (
                <>
                  {product?.principal && (
                    <>
                      <InputFieldWithInfo
                        type="number"
                        label="For how long do you want to pay back the loan? (Months)"
                        name="period"
                        infoTitle="Loan Term"
                        infoDescription={`The minimum time you can take to pay back this loan is ${product.period.min} month, Maximum is ${product.period.max} months`}
                        value={term}
                        error={formState.errors['period']}
                        registration={register('period')}
                        onChange={(event) => {
                          formState.errors['period'] = ''
                          setTermErr()
                          const tt = parseInt(event.target.value)
                          if (tt < product.period.min) {
                            setTermErr(`Months cannot be less than ${product.period.min}`)
                            return
                          } else if (tt > product.period.max) {
                            setTermErr(`Months cannot be more than ${product.period.max}`)
                            return
                          }
                          setTerm(tt);
                          setValue('period', tt)
                          storage.setFormValues({
                            period: tt,
                          });
                        }}
                        className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                      {termErr && (
                        <div role="alert" aria-label={termErr} className="text-sm font-semibold text-red-500">
                          {termErr}
                        </div>
                      )}
                    </>
                  )}
                </>

              )}

              {!isNaN(maxMonthlyPayment) && (
                <>
                  <div className="p-1 rounded-md text-sm text-start mb-2">
                    With {interestRate}% interest, you're required to pay back UGX{' '}
                    {(maxMonthlyPayment)?.toLocaleString()} monthly.
                  </div>
                  <div className="flex flex-col  space-y-2 md:space-y-0 md:flex-row md:items-center md:space-x-2 md:justify-between">
                    <Button
                      className="w-full text-xs"
                      type="button"
                      onClick={() => {
                        localStorage.setItem('acceptMonthlyPayment', 'CONFIRM')
                        setAcceptMonthlyPaymentErr()
                        setAcceptMonthlyPayment('CONFIRM');
                        setMonthlyPayment(maxMonthlyPayment);
                      }}
                    >
                      I agree
                    </Button>
                    <Button
                      className="w-full text-xs"
                      type="button"
                      variant="outline"
                      onClick={() => {
                        localStorage.setItem('acceptMonthlyPayment', 'PROVIDE')
                        setAcceptMonthlyPaymentErr()
                        setAcceptMonthlyPayment('PROVIDE');
                      }}
                    >
                      No,Change amount to pay monthly
                    </Button>
                  </div>
                  {acceptMonthlyPaymentErr && (
                    <div role="alert" aria-label={acceptMonthlyPaymentErr} className="text-sm font-semibold text-red-500 mt-2">
                      {acceptMonthlyPaymentErr}
                    </div>
                  )}
                </>
              )}

              {acceptMonthlyPayment === 'CONFIRM' && (
                <>
                  {product ? (
                    <div className="bg-white mb-3 rounded-2xl p-2 mt-4 shadow flex flex-col items-center justify-center">
                      {amount ? (
                        <>
                          <div className="mb-2 my-2">
                            <div className="font-bold mb-3">Net You'll Receive</div>
                            <div className="">
                              <span className="text-xs">Service Fee:</span> UGX{' '}
                              {(amount
                                ? (serviceFeePercentage / 100) * amount
                                : null
                              )?.toLocaleString()}
                              <br />
                              <span className="text-xs">Caveat Placement Fee:</span>{' '}
                              {product?.applicable_fees?.vehicles?.caveat_placement_fee && (
                                <>
                                  UGX{' '}
                                  {product?.applicable_fees?.vehicles?.caveat_placement_fee?.toLocaleString() ||
                                    0}
                                </>
                              )}
                              <br />
                              {product?.applicable_fees?.vehicles?.tracker_fee?.hire && (
                                <>
                                  <span className="text-xs">Tracker Fee:</span> UGX{' '}
                                  {product?.applicable_fees?.vehicles?.tracker_fee?.hire?.toLocaleString() ||
                                    0}
                                </>
                              )}
                              <br />
                              <span className="text-xs">Insurance Fee:</span> UGX 0
                              <br />
                              <br />
                              <span className="text-xs">Total:</span> UGX {''}
                              {(
                                amount -
                                (amount ? (serviceFeePercentage / 100) * amount : null) -
                                (product?.applicable_fees?.vehicles?.caveat_placement_fee || 0) -
                                (product?.applicable_fees?.vehicles?.tracker_fee?.hire || 0) -
                                (product?.applicable_fees?.vehicles?.tracker_fee?.buy || 0)
                              )?.toLocaleString()}
                            </div>
                          </div>
                          {/* <ComputeNetYouReceive /> */}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="mx-0 md:mx-32 flex flex-col space-y-3 mb-3">
                    {product ? (
                      <div className="bg-primary-100 p-2 rounded-2xl">
                        <div className="text-gray-100 text-xs text-center">
                          Current Network Average Lending Rate
                        </div>
                        <div className="text-white text-center">
                          {averageLendingRate} % per month
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {monthlyPayment ? (
                    <PaymentSchedule
                      amount={amount}
                      interestRate={interestRate}
                      period={term}
                      monthlyPayment={monthlyPayment}
                      paymentMethod={paymentMethod}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
              {(acceptMonthlyPayment === 'PROVIDE' && paymentMethod === 'INTEREST_ONLY') ? (
                <>
                  {errorMessage && <div className="error">{errorMessage}</div>}
                  <div className="mb-3">
                    <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                      How much can you pay back every month?
                    </div>
                    <ReactNumberFormatInputField2
                      prefix="UGX "
                      name="monthlyPayment"
                      value={monthlyPayment}
                      error={formState.errors['monthlyPayment']}
                      registration={register('monthlyPayment')}
                      onChange={(event) => {
                        formState.errors['monthlyPayment'] = ''
                        setPaybackAmountErr()
                        try {
                          const floatValue = parseFloat(event.value);
                          setErrorMessage('');

                          if (isNaN(floatValue)) {
                            // Handle case when input value is not a valid number or empty
                            setMonthlyPayment('');
                          } else if (floatValue === maxMonthlyPayment) {
                            console.log(floatValue);
                            // Handle case when input value is equal to max monthly payment
                            setErrorMessage('Monthly payment must be more than the minimum monthly payment');
                          } else {
                            // Clear error message
                            setErrorMessage('');
                            if (floatValue < maxMonthlyPayment) {
                              console.log('amount is not greater')
                              setPaybackAmountErr('Amount must be greater than UGX ' + maxMonthlyPayment)
                              return
                            }
                            // Set the monthly payment to the parsed float value
                            setMonthlyPayment(floatValue);
                            storage.setFormValues({
                              monthlyPayment: floatValue,
                            });
                          }
                        } catch (error) {
                          console.log('amount is not a number')
                        }

                      }}
                      placeholder={
                        !isNaN(maxMonthlyPayment) &&
                        `More than UGX ${(maxMonthlyPayment)?.toLocaleString()}`
                      }
                      min={maxMonthlyPayment}
                      //max={parseInt(product?.principal?.max)}
                      className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                    {paybackAmountErr && (
                      <div role="alert" aria-label={paybackAmountErr} className="text-sm font-semibold text-red-500">
                        {paybackAmountErr}
                      </div>
                    )}
                    {monthlyPayment && !errorMessage ? (
                      <PaymentSchedule
                        amount={amount}
                        interestRate={interestRate}
                        period={term}
                        monthlyPayment={monthlyPayment}
                        paymentMethod={paymentMethod}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (<></>)}
              <div className="flex flex-row items-center space-x-2 mt-6">
                <Button variant="outline" onClick={() => prevStep()} className="w-full">
                  Previous
                </Button>

                <Button variant="dark" type="submit" className="w-full">
                  Next
                </Button>
              </div>
            </div>
          )}
        </Form>
      )}
    </>
  );
};
