import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import PropTypes from 'prop-types';

import { useAcceptOffer } from '../api/acceptOffer';

import { useNavigate } from 'react-router-dom';

export const AcceptOffer = ({ id }) => {
  const acceptOfferMutation = useAcceptOffer();

  const { navigate } = useNavigate();

  if (acceptOfferMutation.isLoading) return <FullScreenSpinner />;

  return (
    <>
      <ConfirmationDialog
        icon="info"
        title="Accept Offer"
        body="Are you sure you want to accept this offer?"
        triggerButton={
          <Button variant="primary" className="whitespace-nowrap">
            Accept Offer
          </Button>
        }
        confirmButton={
          <Button
            isLoading={acceptOfferMutation.isLoading}
            type="button"
            variant="primary"
            size="sm"
            onClick={async () => {
              await acceptOfferMutation.mutateAsync({ id: id });
              navigate('/app/my-loan');
            }}
            className="whitespace-nowrap"
          >
            Accept Offer
          </Button>
        }
      />
    </>
  );
};

AcceptOffer.propTypes = {
  id: PropTypes.string.isRequired,
};
