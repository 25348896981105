import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import PhoneInput from 'react-phone-input-2';
import { Button } from 'components/Elements';
import { Form, InputField, LocationField } from 'components/Form';
import { useAuth } from 'lib/auth';
import 'react-phone-input-2/lib/style.css';
import { useApplyForCompanyAccount } from '../api/registerCompany';

const schema = z.object({
  firstName: z.string({
    required_error: "First name is required",
    invalid_type_error: "First name must be a string",
  }),
  lastName: z.string({
    required_error: "Last name is required",
    invalid_type_error: "Last name must be a string",
  }),
  phoneNumber: z.string({
    required_error: "Phone Number is required",
    invalid_type_error: "Phone Number must be a string",
  }),
  email: z.string({
    required_error: "Email is required",
    invalid_type_error: "Email must be a string",
  }).email({ message: 'Invalid email address' }),
  nin: z.string({
    required_error: "National identification number is required",
    invalid_type_error: "National identification number must be a string",
  }),
  workPlace: z.string({
    required_error: "Your Work Place is required",
    invalid_type_error: "Your Work Place must be a string",
  }),
  password: z.string({
    required_error: "Password is required",
    invalid_type_error: "Password must be a string",
  }),
});

const companySchema = z.object({
  name: z.string().nonempty({ message: 'Company name is required' }),
  email_address: z.string().email({ message: 'Invalid email address' }),
  website: z.string().url({ message: 'Invalid Website URL' }).nonempty({ message: 'Website is required' }),
  date_of_inception: z.string().nonempty({ message: 'Date of inception is required' }),
  incoperation_number: z.string().nonempty({ message: 'Incoperation number is required' }),
  tin: z.string().nonempty({ message: 'TIN is required' }),
  representativeFirstName: z.string().nonempty({ message: 'Representative name is required' }),
  representativeLastName: z.string().nonempty({ message: 'Representative name is required' }),
  representativeNIN: z.string().nonempty({ message: 'Representative NIN is required' }),
  representativeEmailAddress: z.string().email({ message: 'Invalid email address' }),
});

export const RegisterForm = ({ onSuccess }) => {
  const { register, isRegistering } = useAuth();

  const [location, setLocation] = useState('');
  const [repNumber, setRepNumber] = useState('');
  const [active, setActive] = useState('PERSONAL');
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('')
  const [nin, setNin] = useState('')
  const [workPlace, setWorkPlace] = useState('')
  const [password, setPassword] = useState('')
  const [loginErr, setLoginErr] = useState('')

  useEffect(() => {
    const getData = async () => {
      const aw = localStorage.getItem('activeWindow')
          if (aw) {
            setActive(aw)
          }
          let sv = localStorage.getItem('individualRegister')
          
          if (sv) {
            sv = JSON.parse(sv)
            // console.log('individualRegister', sv)
            if(sv.firstName){
              setFirstName(sv.firstName)
            }
            if(sv.lastName){
              setLastName(sv.lastName)
              }
              if(sv.phoneNumber){
                setPhoneNumber(sv.phoneNumber)
              }
              if(sv.email){
                setEmail(sv.email)
                }
                if(sv.nin){
                  setNin(sv.nin)
                  }
                  if(sv.workPlace){
                    setWorkPlace(sv.workPlace)
                    }

                    try {
                      const schemaData = schema.safeParse({
                         firstName: sv.firstName || '',
                         lastName: sv.lastName || '',
                         phoneNumber: sv.phoneNumber || '',
                         email: sv.email || '',
                         nin: sv.nin || '',
                         workPlace: sv.workPlace || '',
                         password: '',
                       });
                       if(schemaData.success){
                         console.log('schemaData', schemaData.data)
                       }  else if(schemaData.error){
                        console.log('schemaData fail', schemaData.error.message)
                      }
                     } catch (err) {
                       const formattedErrors = {};
                       err.errors.forEach((error) => {
                         formattedErrors[error.path[0]] = error.message;
                       });
                       console.log(' person err', formattedErrors)
                     }
          }
    }
    getData()
  }, []);

  const registerCompanyMutation = useApplyForCompanyAccount();

  const navigate = useNavigate();

  return (
    <div>
      <h2 className="mb-2 text-center text-xl font-extrabold text-gray-900 dark:text-white">
        Register new account
      </h2>
      <div className="text-sm text-center my-2">
        Get access to your free Finablr account, apply for a loan within 5 minutes!
      </div>
      <div className="bg-gray-100 shadow-md flex flex-row space-x-4 items-center justify-between rounded-md p-2 mb-3 py-3">
        <button
          className={`${active == 'PERSONAL' ? `bg-gray-800 text-white` : `text-gray-800 hover:bg-gray-300`
            } rounded-md p-2 px-4 text-sm`}
          onClick={() => {
            setActive('PERSONAL')
            localStorage.setItem('activeWindow', 'PERSONAL')
          }}
        >
          Personal Account
        </button>
        <button
          onClick={() => {
            setActive('COMPANY')
            localStorage.setItem('activeWindow', 'COMPANY')
          }}
          className={`${active == 'COMPANY' ? `bg-gray-800 text-white` : `text-gray-800 hover:bg-gray-300`
            }  rounded-md p-2 px-4 text-sm `}
        >
          Company Account
        </button>
      </div>
      {active == 'PERSONAL' && (
        <Form
          onSubmit={async (values) => {
            // values.termsAndConditionsAccepted = true;
            // values.privacyPolicyAccepted = true;
            // values.phoneNumber = phoneNumber;
            const data = {
              termsAndConditionsAccepted: true,
              privacyPolicyAccepted: true,
              firstName,
              lastName,
              phoneNumber,
              email,
              nin,
              password,
            }
            await register(data)
            .then((data)=> {
              // console.log('login success', data)
              onSuccess();
            })
            .catch((err)=> {
              // console.log('login error', err);
              setLoginErr(`${err.message}. Try again.`);
            })
          }}
          options={{defaultValues: {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            email: email,
            nin: nin,
            workPlace: workPlace,
            password: '',
          }}}
          schema={schema}
        >
          {({ register, formState, setValue }) => (
            <>
              <InputField
                type="text"
                label="First Name"
                error={formState.errors['firstName']}
                registration={register('firstName')}
                name="firstName"
                value={firstName}
                onChange={(event) => {
                  formState.errors['firstName'] = ''
                  setValue('firstName', event.target.value);
                  const newValues = {
                    firstName: event.target.value,
                  }
                  setFirstName(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="text"
                label="Last Name"
                error={formState.errors['lastName']}
                registration={register('lastName')}
                name="lastName"
                value={lastName}
                onChange={(event) => {
                  formState.errors['lastName'] = ''
                  setValue('lastName', event.target.value);
                  const newValues = {
                    lastName: event.target.value,
                  }
                  setLastName(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              <div className="mb-3">
                  <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                    Phone Number
                  </div>
                  <PhoneInput
                    country={'ug'}
                    countryCodeEditable={false}
                    masks={{ ug: '... ... ...' }}
                    name="phoneNumber"
                    value={phoneNumber}
                    inputStyle={{
                      width: '100%',
                      height: '60px',
                      fontSize: '1rem',
                      border: '1px solid #e5e5e5',
                      borderRadius: '8px',
                    }}
                    containerClass='mt-2'
                    onChange={(phone) => {
                      formState.errors['phoneNumber'] = ''
                      setValue('phoneNumber', `+${phone}`)
                      setPhoneNumber(`+${phone}`)
                      const newValues = {
                        phoneNumber: `+${phone}`,
                      }
                      const existingValues = JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                    }}
                  />
                  {formState.errors['phoneNumber']?.message && (
                  <div role="alert" aria-label={formState.errors['phoneNumber']?.message} className="text-sm font-semibold text-red-500">
                    {formState.errors['phoneNumber']?.message}
                  </div>
                )}
                </div>
                
              <InputField
                type="email"
                label="Email Address"
                error={formState.errors['email']}
                registration={register('email')}
                name="email"
                value={email}
                onChange={(event) => {
                  formState.errors['email'] = ''
                  setValue('email', event.target.value);
                  const newValues = {
                    email: event.target.value,
                  }
                  setEmail(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="text"
                label="Identification Number"
                error={formState.errors['nin']}
                registration={register('nin')}
                caption="Provide either National ID or Passport Number"
                maxLength={14}
                name="nin"
                value={nin}
                onChange={(event) => {
                  formState.errors['nin'] = ''
                  setValue('nin', event.target.value);
                  const newValues = {
                    nin: event.target.value,
                  }
                  setNin(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                label="Work Place"
                error={formState.errors['workPlace']}
                registration={register('workPlace')}
                name="workPlace"
                value={workPlace}
                onChange={(event) => {
                  formState.errors['workPlace'] = ''
                  setValue('workPlace', event.target.value);
                  const newValues = {
                    workPlace: event.target.value,
                  }
                  setWorkPlace(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="password"
                label="Password"
                passwordField={true}
                error={formState.errors['password']}
                registration={register('password')}
                name="password"
                value={password}
                onChange={(event) => {
                  formState.errors['password'] = ''
                  setValue('password', event.target.value);
                  setPassword(event.target.value);
                }}
              />
              {loginErr && (
                  <div role="alert" aria-label={loginErr} className="text-sm font-semibold text-red-500">
                    {loginErr}
                  </div>
                )}
              <div className="">
                By signing up, you are agreeing to the{' '}
                <Link to="/general-policy" className="text-primary-100">
                  General Policy for Lenders and Borrowers
                </Link>
                . This site uses essential cookies. See our Cookie Notice for more information.
              </div>

              <div>
                <Button isLoading={isRegistering} type="submit" className="w-full">
                  Sign Up
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
      {active == 'COMPANY' && (
        <Form
          onSubmit={async (values) => {
            values.termsAndConditionsAccepted = true;
            values.privacyPolicyAccepted = true;
            values.creditCheckConsent = true;
            values.phone_number = phoneNumber;
            values.representativePhoneNumber = repNumber;
            await registerCompanyMutation.mutateAsync({ data: values });
            navigate('/auth/company-request-submitted');
          }}
          schema={companySchema}
        >
          {({ register, formState }) => (
            <>
              <InputField
                type="text"
                label="Company Name"
                error={formState.errors['name']}
                registration={register('name')}
              />
              <InputField
                label="Location"
                error={formState.errors['location']}
                registration={register('location')}
              />
              <div className="">Company Number</div>
              <PhoneInput
                country={'ug'}
                masks={{ ug: '... ... ...' }}
                value={phoneNumber}
                inputStyle={{
                  width: '100%',
                  height: '60px',
                  fontSize: '1rem',
                  border: '1px solid #e5e5e5',
                  borderRadius: '8px',
                }}
                onChange={(phone) => setPhoneNumber(`+${phone}`)}
              />
              <InputField
                type="email"
                label="Email Address"
                error={formState.errors['email_address']}
                registration={register('email_address')}
              />

              <InputField
                type="text"
                label="Website"
                error={formState.errors['website']}
                registration={register('website')}
              />
              <InputField
                type="date"
                label="Date of Inception"
                error={formState.errors['date_of_inception']}
                registration={register('date_of_inception')}
              />
              <InputField
                type="number"
                label="Incoperation Number"
                error={formState.errors['incoperation_number']}
                registration={register('incoperation_number')}
              />
              <InputField
                type="number"
                label="TIN"
                error={formState.errors['tin']}
                registration={register('tin')}
              />
              <InputField
                type="text"
                label="Representative First Name"
                error={formState.errors['representativeFirstName']}
                registration={register('representativeFirstName')}
              />
              <InputField
                type="text"
                label="Representative Last Name"
                error={formState.errors['representativeLastName']}
                registration={register('representativeLastName')}
              />
              <div className="text-sm">Representative Phone Number</div>
              <PhoneInput
                country={'ug'}
                masks={{ ug: '... ... ...' }}
                value={repNumber}
                inputStyle={{
                  width: '100%',
                  height: '60px',
                  fontSize: '1rem',
                  border: '1px solid #e5e5e5',
                  borderRadius: '8px',
                }}
                onChange={(phone) => setRepNumber(`+${phone}`)}
              />
              <InputField
                type="email"
                label="Representative Email Address"
                error={formState.errors['representativeEmailAddress']}
                registration={register('representativeEmailAddress')}
              />
              <InputField
                label="Representative NIN"
                error={formState.errors['representativeNIN']}
                registration={register('representativeNIN')}
              />
              <div className="">
                By signing up, you are agreeing to the{' '}
                <Link to="/general-policy" className="text-primary-100">
                  General Policy for Lenders and Borrowers
                </Link>
                . This site uses essential cookies. See our Cookie Notice for more information.
              </div>

              <div>
                <Button
                  isLoading={registerCompanyMutation.isLoading}
                  type="submit"
                  className="w-full"
                >
                  Sign Up
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          Already have an account? {''}
          <Link to="../login" className="font-medium text-primary-100 hover:text-blue-500">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
