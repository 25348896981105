import React, { useState } from 'react';

export const Accordion = ({ title, children, caption }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-fill mx-auto shadow-md rounded-xl bg-white">
      <div className=" rounded-xl">
        <div
          className="flex justify-between items-center p-4 cursor-pointer"
          onClick={toggleAccordion}
        >
          <div className="flex flex-col space-y-1">
            <div className="text-lg font-medium">{title}</div>
            <div className="text-sm font-light">{caption}</div>
          </div>
          <svg
            className={`w-6 h-6 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isOpen ? 'M5 15l7-7 7 7' : 'M19 9l-7 7-7-7'}
            />
          </svg>
        </div>
        {isOpen && (
          <div className="p-4 border-t border-gray-300">
            {/* Content Goes Here */}
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
