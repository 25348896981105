import { ContentLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';

import LendMoneyImg from 'assets/lend-money.svg';
import MyPortfolioImg from 'assets/my-portfolio.svg';
import { Head } from 'components/Head';
import { useNavigate } from 'react-router-dom';
import { OpenPigeon } from 'features/pigeonLoans';
import { useMyLoan } from 'features/lendMoney';
import { Dialog, DialogDescription, DialogTitle, Spinner } from 'components/Elements';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { DevelopmentDialog } from '../components/DevelopmentDialog';

import { useRequestActivateAccount } from '../api/requestActivateAccount';
import { useLenderProfile } from '../api/getLenderProfile';
import { LoanProgress } from '../components/LoanProgress';
import { getNextPayment } from 'utils/getNextPayment';
import { useUserActivity } from 'hooks/useUserActivity';
import { axios } from 'lib/axios';
import storage from 'utils/storage';

export const Dashboard = () => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const myLoanQuery = useMyLoan();

  const [show, setShow] = useState(false);

  const [showDevelopment, setShowDevelopment] = useState(false);

  const requestActivateMutation = useRequestActivateAccount();

  const lenderProfileQuery = useLenderProfile();

  useEffect(() => {
    const showDevelopment = localStorage.getItem('showDevelopment');

    if (!showDevelopment) {
      const timer = setTimeout(() => {
        setShowDevelopment(true);
      }, 20000);
      return () => clearTimeout(timer);
    }
  }, []);

  if (user) {
    const updateActivity = () => {
      axios.post('/sessions/update-activity', { userId: user?._id });
    };
    useUserActivity(updateActivity);
  }

  const handleCloseDevelopmentDialog = () => {
    localStorage.setItem('showDevelopment', 'true');
    setShowDevelopment(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  const handleLendMoney = () => {
    if (myLoanQuery.data?.amount?.amountRequired) {
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: 'You currently have a loan application in progress. You can only lend money after clearing your loan.',
      });
      return;
    }
    if (user.role === 'LENDER') {
      if (!lenderProfileQuery?.data) {
        handleOpen();
      } else {
        navigate('/app/lend-money');
      }
    } else {
      handleOpen();
    }
  };

  const handleMyPortfolio = () => {
    if (user.role === 'LENDER') {
      if (!lenderProfileQuery?.data) {
        handleOpen();
      } else {
        navigate('/app/my-portfolio');
      }
    } else {
      handleOpen();
    }
  };

  if (myLoanQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  const nextPayment = getNextPayment(myLoanQuery?.data?.payments);

  // if (!lenderProfileQuery.data) return null;

  return (
    <ContentLayout title={`Hey 👋🏼 ${user.basicInformation?.firstName}`}>
      <Head title="Home" />
      <DevelopmentDialog isOpen={showDevelopment} onClose={handleCloseDevelopmentDialog} />

      {user.status === 'IMPORT' ? (
        <button
          onClick={async () => {
            await requestActivateMutation.mutateAsync();
            Swal.fire({
              title: 'Nice',
              text: 'Please check your email and follow instructions to confirm and activate your account.',
            });
          }}
          className="bg-orange-100 text-orange-500 p-4 rounded-2xl mb-4 font-bold text-lg"
        >
          Your account was recently imported. Please click this link to confirm your email address
          and activate your account.
        </button>
      ) : (
        <div className="mb-4 font-bold text-lg">What would you like to do today?</div>
      )}
      {user.status !== 'IMPORT' && user.status !== 'ACTIVE' && (
        <button
          onClick={async () => {
            await requestActivateMutation.mutateAsync();
            Swal.fire({
              title: 'Nice',
              text: 'Please check your email and follow instructions to confirm and activate your account.',
            });
          }}
          className="bg-orange-100 text-orange-500 p-4 rounded-2xl mb-4 font-bold text-lg"
        >
          <strong>Activate your account now to borrow or lend.</strong>
          <br />
          Please click this link to confirm your email address and activate your account.
        </button>
      )}
      <div className="">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <OpenPigeon />
          <button
            onClick={handleLendMoney}
            disabled={user.status === 'ACTIVE' ? false : true}
            className={`flex flex-col items-center bg-white justify-center space-y-1 rounded-2xl shadow-lg p-12`}
          >
            <img
              src={LendMoneyImg}
              alt="borrow money"
              className={`h-16  mb-3 ${user.status === 'ACTIVE' ? `` : `grayscale`}`}
            />
            <div
              className={`${
                user.status === 'ACTIVE' ? `text-secondary-100` : `text-gray-300`
              } text-xl font-bold`}
            >
              Lend Money
            </div>
            <div className={`text-center ${user.status === 'ACTIVE' ? `` : `text-gray-300`}`}>
              Earn your set interest on loan returns.
            </div>
          </button>

          {myLoanQuery.isLoading && <div>Loading...</div>}
          {myLoanQuery.data?.amount?.loanRequired && nextPayment?.status !== 'COMPLETE' ? (
            <button
              onClick={() => {
                navigate('/app/my-loan');
              }}
              className={`flex flex-col items-center bg-white justify-center space-y-1 rounded-2xl shadow-lg p-12`}
            >
              <img
                src={MyPortfolioImg}
                alt="borrow money"
                className={`h-16 mb-3 ${user.status === 'ACTIVE' ? `` : `grayscale`}`}
              />
              <div
                className={`${
                  user.status === 'ACTIVE' ? `text-primary-100` : `text-gray-300`
                } text-xl font-bold`}
              >
                My Loan
              </div>
              <div className={`text-start ${user.status === 'ACTIVE' ? `` : `text-gray-300`}`}>
                View your current ongoing loan.
              </div>
            </button>
          ) : (
            <button
              onClick={handleMyPortfolio}
              disabled={user.status === 'ACTIVE' ? false : true}
              className={`flex flex-col items-center bg-white justify-center space-y-1 rounded-2xl shadow-lg p-12`}
            >
              <img
                src={MyPortfolioImg}
                alt="borrow money"
                className={`h-14 mb-3 ${user.status === 'ACTIVE' ? `` : `grayscale`}`}
              />
              <div
                className={`${
                  user.status === 'ACTIVE' ? `text-primary-100` : `text-gray-300`
                } text-xl font-bold`}
              >
                My Portfolio
              </div>
              <div className={`text-start ${user.status === 'ACTIVE' ? `` : `text-gray-300`}`}>
                View all your current transaction.
              </div>
            </button>
          )}
        </div>
        {/* <div className="mb-4 font-bold text-lg">Loan tips</div>
        <div className="bg-white rounded-2xl shadow-lg p-12">
          <TypeAnimation
            sequence={[
              'Before taking out a loan, make sure you set the right terms, including interest rates that are favorable for you.',
              4000,
              `Don't settle for the first loan offer you receive. Shop around and compare rates from multiple lenders to ensure you're getting the best deal`,
              4000,
              'Your credit score plays a big role in determining your loan eligibility and interest rates. Check your score before applying for a loan and work on improving it if necessary.',
              4000,
              'Only borrow what you can afford to repay. Consider your budget and ensure that the monthly loan payments fit comfortably within your financial situation.',
              4000,
              'Always read the loan agreement carefully and understand all the terms and conditions before signing. Pay attention to any hidden fees or penalties.',
              4000,
              'There are various types of loans available, such as personal loans, secured loans, and lines of credit. Explore different options to find the best fit for your needs.',
              4000,
              'Be wary of lenders who offer loans with extremely high-interest rates or unrealistic terms. Stick to reputable lenders with transparent practices.',
              4000,
              'Think about the long-term impact of taking out a loan on your financial goals. Will it help you achieve your objectives, or could it potentially lead to more debt?',
              4000,
              'Develop a solid repayment plan before taking out a loan. Make sure you have a strategy in place to make timely payments and avoid defaulting on the loan.',
              4000,
              `If you're unsure about whether taking out a loan is the right decision for you, consider seeking advice from a financial advisor.`,
              4000,
            ]}
            wrapper="span"
            speed={10}
            repeat={Infinity}
            cursor={false}
            aria-expanded={false}
            deletionSpeed={100}
          />
        </div> */}
      </div>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">Oops!</DialogTitle>
          <DialogDescription>
            <div className="">
              You currently do not have a lenders profile. Would you like to create one?
            </div>
            <div className="mx-auto">
              <div className="flex flex-row items-center space-x-12 my-6"></div>
            </div>
          </DialogDescription>
          <div className="flex flex-row items-center space-x-2">
            <button onClick={handleClose} className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl">
              Cancel
            </button>
            <button
              onClick={() => navigate('/app/lend-money/complete-lender-profile')}
              className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl text-white bg-secondary-100"
            >
              Create Profile
            </button>
          </div>
        </div>
      </Dialog>
      {myLoanQuery.data?.loanProgress > 0 && (
        <LoanProgress loanProgress={myLoanQuery.data?.loanProgress} />
      )}
    </ContentLayout>
  );
};
