import { Button, Dialog, DialogTitle, Spinner } from 'components/Elements';
import { UPLOADS_URL } from 'config';
import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { MdWarning } from 'react-icons/md';
import { Form } from 'components/Form';
import { useApproveLenderSignature } from '../api/approveLenderSignature';

export function SubmitLenderSignature({ id, location }) {
  const [show, setShow] = useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [updateSignature, setUpdateSignature] = useState(false);
  const sigPadRef = useRef(null);

  const confirmLenderSignatureMutation = useApproveLenderSignature();

  const clear = () => {
    sigPadRef.current?.clear();
  };

  const trim = () => {
    const trimmedData = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
    setTrimmedDataURL(trimmedData);
    setFinish(true);
  };

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setUpdateSignature(false);
    setTrimmedDataURL(null);
    clear();
  };

  return (
    <div>
      <Button
        variant="inverse"
        startIcon={<MdWarning className="text-red-500" />}
        onClick={handleOpen}
      >
        Signature Required
      </Button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            Signature Required
          </DialogTitle>
          <>
            <div className="">
              Please note that you are about to accept to use the signature below on your loan
              contract.
            </div>
            <br />
            <>
              <div className="border border-gray-200 p-3 rounded-2xl mb-3">
                <img
                  src={UPLOADS_URL + '/signatures/' + location}
                  className="h-16"
                  alt="Signature"
                />
              </div>
              <div className="mb-2">Would you like to proceed with this action?</div>
              <div className="flex flex-row items-center space-x-2 mb-3">
                <Button variant="inverse" size="sm" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  size="sm"
                  isLoading={confirmLenderSignatureMutation.isLoading}
                  onClick={async () => await confirmLenderSignatureMutation.mutateAsync({ id: id })}
                >
                  Accept Signature
                </Button>
              </div>
            </>
          </>
        </div>
      </Dialog>
    </div>
  );
}
