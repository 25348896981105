export function getLateFeePercentage(amount, lateFees) {
  for (let fee of lateFees) {
    if (
      fee.range &&
      fee.range.min !== undefined &&
      fee.range.max !== undefined &&
      amount >= fee.range.min &&
      amount <= fee.range.max
    ) {
      return fee.percentage;
    }
  }
  return null;
}
