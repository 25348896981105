import { useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { RegisterForm } from '../components/RegisterForm';

export const Register = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="mt-4">
        <RegisterForm onSuccess={() => navigate('/please-confirm-email')} />
      </div>
    </Layout>
  );
};
