export function getServiceFeePercentage(amount, serviceFees) {
  for (let fee of serviceFees) {
    // check if the amount is within the range
    if (
      parseInt(amount) >= parseInt(fee.range.min) &&
      parseInt(amount) <= parseInt(fee.range.max)
    ) {
      return fee.percentage;
    }
  }
  return null;
}
