import React, { useEffect } from 'react';
import { AppProvider } from 'providers/app';
import { AppRoutes } from 'routes';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import CookieConsent, {
  getCookieConsentValue,
  resetCookieConsentValue,
} from 'react-cookie-consent';

import 'sweetalert2/src/sweetalert2.scss';
import { useAuth } from 'lib/auth';

function App() {
  useEffect(() => {
    const consent = getCookieConsentValue();
    if (consent === 'false') {
      // Reset tracking preferences or any other settings if user declined cookies
    }
  }, []);

  return (
    <AppProvider>
      <AppRoutes />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        onDecline={() => {
          // Optionally reset consent value
          resetCookieConsentValue();
          Swal.fire({
            icon: 'info',
            title: 'Cookies Declined',
            text: 'Your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.',
          });
        }}
        cookieName="userCookieConsent"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        declineButtonStyle={{ color: '#fff', backgroundColor: '#f44336', fontSize: '13px' }}
      >
        This platform stores cookies on your computer. These cookies are used to collect information
        about how you interact with our platform and allow us to remember you. We use this
        information in order to improve and customize your browsing experience and for analytics and
        metrics about our visitors both on this platform and other media. To find out more about the
        cookies we use, see our{' '}
        <a href="/privacy-policy" style={{ color: '#4e503b' }}>
          Privacy Policy
        </a>
        .
      </CookieConsent>
    </AppProvider>
  );
}

export default App;
