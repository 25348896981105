import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';
import { ContractLists } from '../components/ContractLists';

export const AuditLog = () => {
  const routes = [
    {
      name: 'Loans',
      path: '/app/loans',
    },
    {
      name: 'Contracts',
      path: '/app/contracts',
    },
    {
      name: 'Contract',
      path: '/app/contracts/:id',
    },
    {
      name: 'Audit Log',
      path: '/app/contracts/:id/audit-log',
    },
  ];
  const navigate = useNavigate();

  return (
    <ContentLayout
      title="Audit Log"
      end={<Button onClick={() => navigate('/app')}>Back Home</Button>}
    >
      <Breadcrumbs routes={routes} />
      <div className="bg-white rounded-2xl shadow mt-8">
        <div className="p-4">Jonathan.CM2344 signed contract on 19/02/2024 at 12:38pm</div>
        <hr />
        <div className="p-4">Ronald.CM2344 signed contract on 19/02/2024 at 12:38pm</div>
      </div>
    </ContentLayout>
  );
};
