import React, { useState } from 'react';
import { Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { Info } from 'react-feather';

export function DevelopmentDialog({ isOpen, onClose }) {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  const handleProceed = () => {
    // Handle proceed action, if needed
    onClose();
  };

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} initialFocus={null}>
      <div className="inline-block align-start bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top sm:max-w-lg sm:w-full sm:p-6">
        <DialogTitle className="text-xl flex flex-col items-center space-y-2 justify-center text-center font-bold mb-4">
          <Info className="h-8 w-8 text-primary-100" />
          <div className="">Message from CEO.</div>
        </DialogTitle>
        <DialogDescription>
          <div className="text-start">
            <div className="text-center text-gray-800">
              Thank you for using our platform. Please be aware that you are currently accessing a{' '}
              <span className="text-primary-100">beta version.</span>
              <br />
              <br />
              We're diligently working to enhance your experience and address any issues you may
              encounter. Your feedback is invaluable to us, and any bugs you experience will be
              promptly reported to our developers.
            </div>
          </div>
        </DialogDescription>
        <div className="flex flex-row items-center justify-center mt-4 space-x-2">
          <button onClick={handleClose} className="p-2 px-4 bg-gray-100 w-full rounded-2xl">
            Close
          </button>
          {/* If there's a specific action needed for proceeding */}
          {/* <button onClick={handleProceed} className="p-2 px-4 bg-gray-100 w-full rounded-2xl text-white bg-primary-100">
            Proceed
          </button> */}
        </div>
      </div>
    </Dialog>
  );
}
