import React, { useEffect } from 'react';
import { useAddToWishlist } from '../api/addToWishlist';
import { Button, Spinner } from 'components/Elements';
import { Heart } from 'react-feather';
import { useGetWishlistItem } from '../api/getWishlistItem';
import { useRemoveFromWishlist } from '../api/removeFromWishlist';

export const AddToWishlist = ({ loanId }) => {
  const addToWishlistMutation = useAddToWishlist();

  const removeFromWishlistMutation = useRemoveFromWishlist();

  const wishlistItemQuery = useGetWishlistItem({ id: loanId });

  if (wishlistItemQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  if (!wishlistItemQuery.data) return null;

  if (wishlistItemQuery.data?.isWishlisted) {
    return (
      <Button
        variant="inverseSecondary"
        startIcon={<Heart className="h-4 w-4" />}
        isLoading={removeFromWishlistMutation.isLoading}
        onClick={async () => {
          await removeFromWishlistMutation.mutateAsync({ data: { loanId } });
        }}
      >
        Remove from Wishlist
      </Button>
    );
  }

  return (
    <Button
      variant="secondary"
      startIcon={<Heart className="h-4 w-4" />}
      isLoading={addToWishlistMutation.isLoading}
      disabled={wishlistItemQuery.data?.isWishlisted}
      onClick={async () => {
        await addToWishlistMutation.mutateAsync({ data: { loanId } });
      }}
    >
      Add To Wishlist
    </Button>
  );
};
