import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getGuarantor = ({ phone }) => {
  return axios.get(`/guarantors/${phone}`);
};

export const useGuarantor = ({ phone, config }) => {
  return useQuery({
    ...config,
    queryKey: ['guarantor', phone],
    queryFn: () => getGuarantor({ phone }),
  });
};
