import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const acceptOffer = ({ data, id }) => {
  return axios.post(`/loans/accept-offer/${id}`, data);
};

export const useAcceptOffer = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingLoan) => {
      await queryClient.cancelQueries(['loan', updatingLoan?.id]);

      const previousLoan = queryClient.getQueryData[('loan', updatingLoan?.id)];

      queryClient.setQueryData(['loan', updatingLoan?.id], {
        ...previousLoan,
        ...updatingLoan.data,
        id: updatingLoan.id,
      });

      return { previousLoan };
    },
    onError: (_, __, context) => {
      if (context?.previousLoan) {
        queryClient.setQueryData(['loan', context.previousLoan.id], context.previousLoan);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['loan', data?.loan?._id]);
      queryClient.refetchQueries('offers');
      addNotification({
        type: 'success',
        title: 'Offer accepted',
      });
    },
    ...config,
    mutationFn: acceptOffer,
  });
};
