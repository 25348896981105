import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useAuth } from 'lib/auth';

export const uploadFiles = ({ data }) => {
  return axios.patch(`/accounts/upload-files`, data);
};

uploadFiles.propTypes = {
  id: PropTypes.string.isRequired,
};

export const useUploadFiles = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  const { refetchUser } = useAuth();

  return useMutation({
    onMutate: async (updatingUser) => {
      await queryClient.cancelQueries(['auth-user', updatingUser?.id]);

      const previousUser = queryClient.getQueryData[('auth-user', updatingUser?.id)];

      queryClient.setQueryData(['auth-user', updatingUser?.id], {
        ...previousUser,
        ...updatingUser.data,
        id: updatingUser.id,
      });

      return { previousUser };
    },
    onError: (_, __, context) => {
      if (context?.previousUser) {
        queryClient.setQueryData(['auth-user', context.previousUser.id], context.previousUser);
      }
    },
    onSuccess: (data) => {
      refetchUser();
      addNotification({
        type: 'success',
        title: 'Awesome, your files have been uploaded!',
      });
    },
    ...config,
    mutationFn: uploadFiles,
  });
};
