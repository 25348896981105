import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button, Spinner } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';
import { Heart } from 'react-feather';
import { useLoans } from '../api/getLoans';
import { useEffect } from 'react';
import { useAuth } from 'lib/auth';

export const LendMoney = () => {
  const routes = [
    {
      name: 'Lend Money',
      path: '/app/lend-money',
    },
  ];
  const navigate = useNavigate();

  const { user } = useAuth();

  const loansQuery = useLoans();

  useEffect(() => {
    if (user.role !== 'LENDER') navigate('/app');
  }, [user.role, navigate]);

  if (loansQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!loansQuery.data) return null;

  return (
    <ContentLayout
      title="Lend Money"
      end={
        <Button variant="secondary" onClick={() => navigate('/app')}>
          Back Home
        </Button>
      }
    >
      <Breadcrumbs routes={routes} />

      <div className="mt-4">
        <div className="p-4 mb-4 font-semibold">Recent Loans</div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {loansQuery.data.map((loan) => (
            <LoanItem
              key={loan?._id}
              name={loan?.client?.authentication?.username}
              duration={loan?.term}
              offers={loan?.offers?.length}
              isNew={loan?.status === 'EFL_APPROVED' && true}
              amount={loan?.amount?.loanRequired?.toLocaleString()}
              interestRate={loan?.interestRate}
              monthlyPayment={loan?.monthlyPayment?.toLocaleString()}
              _id={loan?._id}
            />
          ))}
        </div>
      </div>
    </ContentLayout>
  );
};

const LoanItem = ({ _id, name, duration, amount, offers, interestRate, monthlyPayment, isNew }) => {
  return (
    <Link to={`/app/lend-money/${_id}`} className="rounded-2xl shadow">
      <div
        className={`${
          isNew ? 'bg-white' : 'bg-secondary-100'
        } rounded-t-2xl p-3 flex flex-row jusify-between`}
      >
        <div className="">
          <div className="font-bold">{name}</div>
          <div className="">Payable in {duration} months</div>
          <div className="">Offers: {offers ? offers : 'No offers yet'}</div>
        </div>
        <div className="">
          <Heart className="text-white" />
        </div>
      </div>
      <div className="p-3 flex flex-row items-center justify-between">
        <div className="">UGX {amount.toLocaleString()}</div>
        <div className="">
          <div className="">{interestRate}% per month</div>
          <div className="text-xs">UGX {monthlyPayment.toLocaleString()} monthly</div>
        </div>
      </div>
    </Link>
  );
};
