import { Button, Spinner } from 'components/Elements';
import { CheckboxField, Form } from 'components/Form';
import { useGuarantor } from '../../api/getGuarantor';
import React, { useState } from 'react';
import { formatText } from 'utils/formatText';
import storage from 'utils/storage';

export const TermsAndConditions = ({ nextStep, prevStep }) => {
  const values = storage.getFormValues();
  const [acceptTerms, setAcceptTerms] = useState(false);

  const guarantorQuery = useGuarantor({ phone: values?.guarantor });

  if (guarantorQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!guarantorQuery.data) return null;

  return (
    <>
      <Form
        id="create-loan"
        onSubmit={async () => {
          storage.setFormValues({
            acceptTerms,
          });
          nextStep();
        }}
      >
        {({ register, formState }) => (
          <div className="px-12 md:px-24 pt-12">
            <div className="text-lg mb-6">Review your loan application</div>
            <div className="border border-gray-700 rounded-md shadow bg-white p-6">
              <div className="rounded-xl border border-gray-200 mb-4">
                <div className="bg-primary-100 p-2 rounded-t-xl text-white">Loan Details</div>
                <div className="p-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                    <div className="text-xs md:text-sm">Payment Method</div>
                    <div className="text-sm md:text-md font-semibold">
                      {formatText(values.paymentMethod)}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                    <div className="text-xs md:text-sm">Amount Required</div>
                    <div className="text-sm md:text-md font-semibold">
                      UGX {values.amount?.toLocaleString()}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                    <div className="text-xs md:text-sm">Monthly Installment</div>
                    <div className="text-sm md:text-md font-semibold">
                      UGX {values.monthlyPayment?.toLocaleString()}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                    <div className="text-xs md:text-sm">Interest Rate</div>
                    <div className="text-sm md:text-md font-semibold">{values.interestRate} %</div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                    <div className="text-xs md:text-sm">Duration</div>
                    <div className="text-sm md:text-md font-semibold">{values.period} months</div>
                  </div>
                </div>
              </div>

              <div className="rounded-xl border border-gray-200 mb-4">
                <div className="bg-primary-100 p-2 rounded-t-xl text-white">Guarantor</div>
                <div className="p-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                    <div className="text-xs md:text-sm">Name</div>
                    <div className="font-semibold text-sm md:text-md">
                      {guarantorQuery.data?.name}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                    <div className="text-xs md:text-sm">Phone Number</div>
                    <div className="font-semibold text-sm md:text-md">
                      {guarantorQuery.data?.phoneNumber}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                    <div className="text-xs md:text-sm">Email Address</div>
                    <div className="font-semibold text-sm md:text-md overflow-hidden">
                      {guarantorQuery.data?.email}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="rounded-xl border border-gray-200 mb-4"
                style={{ height: '300px', overflow: 'hidden', overflowY: 'scroll' }}
              >
                <div className="bg-primary-100 p-2 rounded-t-xl text-white">
                  Terms and Conditions
                </div>
                <div className="p-4">
                  <div className="mb-2 text-sm md:text-md">
                    <b>1. Introduction:</b> These terms govern the lending activities conducted
                    through the Finablr platform operated by Enabling Finance Limited.
                  </div>

                  <div className="mb-2 text-sm md:text-md">
                    <b>2. Legal and Regulatory Information:</b> EFL is registered in Uganda and
                    authorized by the Uganda Microfinance Regulatory Authority. The company manages
                    the platform connecting lenders and borrowers, oversees loan agreements, and
                    handles repayments.
                  </div>
                  <div className="mb-2 text-sm md:text-md">
                    <b>3. Lender Representations and Warranties:</b> Lenders must meet certain
                    criteria including being licensed in Uganda or consenting to lending under the
                    platform's terms. They must also understand the risks involved and comply with
                    relevant laws.
                  </div>
                  <div className="mb-2 text-sm md:text-md">
                    <b>4. Company Representation on Behalf of Lender:</b> The company acts as an
                    agent for lenders in managing loan agreements and recovery processes.
                  </div>
                  <div className="mb-2 text-sm md:text-md">
                    <b>5. Provision Fund:</b> A discretionary fund is available to lenders in case
                    of borrower default, but compensation is not guaranteed.
                  </div>
                  <div className="mb-2 text-sm md:text-md">
                    <b>6. Costs and Charges:</b> EFL charges commissions on interest and late fees
                    based on the lender's licensing status.
                  </div>
                  <div className="mb-2 text-sm md:text-md">
                    <b>7. Collection and Company Recovery on Delinquent Loans:</b> EFL makes efforts
                    to minimize delinquency and may take time to prepare collateral for disposal in
                    case of default.
                  </div>
                  <div className="mb-2 text-sm md:text-md">
                    <b>8. Collection/Collection Account:</b> Funds are held in trust in a designated
                    account and disbursed accordingly.
                  </div>
                  <div className="mb-2 text-sm md:text-md">
                    <b>9. Holding of Lenders' Funds by Company:</b> Lenders may opt to have their
                    funds managed by the company for efficiency, with the ability to recall funds
                    upon notice.
                  </div>
                  <div className="mb-2 text-sm md:text-md">
                    <b>10. Risk Associated:</b> Lending involves risks, and EFL does not guarantee
                    repayment. Collateral is held to mitigate losses, and in case of loss, detailed
                    explanations are provided.
                  </div>
                  <div className="mb-2 text-sm md:text-md">
                    <b>11. Term and Termination:</b> Terms can be terminated by either party with
                    notice, and funds are returned upon termination.
                  </div>
                  <div className="mb-2 text-sm md:text-md">
                    <b>12. Dispute Resolution, Complaints, and Communications:</b> Disputes are
                    resolved according to Ugandan law, with avenues for complaints and
                    communications provided by the company.
                  </div>

                  <div className="mb-2 text-sm md:text-md">
                    By using the Finablr platform, you confirm your agreement to these terms and
                    conditions.
                  </div>
                </div>
                <div className="m-2">
                  <CheckboxField
                    label="Accept Terms and Conditions"
                    registration={register('acceptTerms')}
                    error={formState.errors['acceptTerms']}
                    onChange={(e) => setAcceptTerms(true)}
                  />
                </div>
              </div>
              <div className="mx-0 md:mx-32 flex flex-col space-y-3">
                <div className="flex flex-row items-center space-x-2">
                  <Button variant="outline" onClick={() => prevStep()} className="w-full">
                    Previous
                  </Button>
                  <Button
                    disabled={acceptTerms ? false : true}
                    variant="dark"
                    type="submit"
                    className="w-full"
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Form>
    </>
  );
};
