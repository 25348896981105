import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button, Spinner } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';
import { useOffers } from '../api/getMyOffers';
import { AcceptOffer } from '../components/AcceptOffer';
import { PaymentSchedule } from 'features/pigeonLoans';

export const Offers = () => {
  const offersQuery = useOffers();

  if (offersQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }
  if (!offersQuery.data) return null;

  const routes = [
    {
      name: 'My Loan',
      path: '/app/my-loan',
    },
    {
      name: 'Offers',
      path: '/app/my-loan/offers',
    },
  ];

  return (
    <ContentLayout title="Offers">
      <Breadcrumbs routes={routes} />
      <div className="mt-8 grid grid-cols-1 gap-4 items-center">
        {offersQuery.data
          .filter((offer) => offer?.status === 'NEW')
          .map((offer) => (
            <div
              key={offer._id}
              className="bg-white rounded-2xl shadow p-8 grid grid-cols-1 md:grid-cols-6 gap-4"
            >
              <div className="">
                <div className="text-gray-500 text-sm">Amount</div>
                <div className="">UGX {offer?.amount?.toLocaleString()}</div>
              </div>
              <div className="">
                <div className="text-gray-500 text-sm">Interest</div>
                <div className="">{offer?.interestRate}% per month</div>
              </div>
              <div className="">
                <div className="text-gray-500 text-sm">Duration</div>
                <div className="">{offer?.term} months</div>
              </div>
              <div className="">
                <div className="text-gray-500 text-sm">Monthly</div>
                <div className="">UGX {offer?.monthlyPayment?.toLocaleString()}</div>
              </div>
              <div className="flex flex-row space-x-2 items-center">
                <PaymentSchedule
                  amount={offer?.amount}
                  interestRate={offer?.interestRate}
                  monthlyPayment={offer?.monthlyPayment}
                />
                <AcceptOffer id={offer._id} />
              </div>
            </div>
          ))}
      </div>
    </ContentLayout>
  );
};
