import React, { useState } from 'react';
import { Image } from 'react-feather';
import { FieldWrapper } from './FieldWrapper';

export const UploadField = (props) => {
  const {
    title,
    error,
    required,
    x,
    y,
    onChange,
    size,
    preview = '',
    caption,
    registration,
    button,
  } = props;

  return (
    <>
      <h1 className="text-gray-700 dark:text-gray-200 font-bold">{title}</h1>
      <span className="text-gray-600 text-sm">
        {caption ? caption : `Images of (${x} x ${y}) px are recommended. Max file size: ${size}`}
      </span>
      <>
        {preview ? (
          <>
            <div className="border border-dotted rounded p-2 flex flex-col items-center justify-center shadow-sm">
              <div className="flex flex-row justify-end">
                <div>{button}</div>
              </div>
              <img
                src={preview}
                className="w-1/2"
                alt="uploaded to server"
                onClick={() => {
                  window.open(preview, '_blank');
                }}
              />
            </div>
          </>
        ) : (
          <div className="border border-dotted rounded p-10 flex flex-col items-center justify-center mt-4 shadow-sm">
            <Image className="text-gray-300 h-20 w-20" />
            <span className="text-gray-500 text-sm"></span>
            <label className="">
              <span className="mt-2 text-base leading-normal">
                <span className="text-red-500 cursor-pointer">Browse</span> for image.
              </span>
              <input
                type="file"
                accept="image/*"
                name="file"
                className="hidden"
                onChange={onChange}
              />
            </label>
            
          </div>
        )}
      </>
      {error && (
        <div role="alert" aria-label={error} className="text-sm font-semibold text-red-500">
          {error}
        </div>
      )}
    </>
  );
};
