import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { CheckboxField, Form, InputField, ReactMultiSelectField } from 'components/Form';
import { useRef, useState } from 'react';
import { useCreateLenderProfile } from '../api/createLenderProfile';
import Swal from 'sweetalert2';
import SignatureCanvas from 'react-signature-canvas';
import { FaInfoCircle } from 'react-icons/fa';
import { MdWarning } from 'react-icons/md';
import { Stepper } from '../components/Stepper';

export const CompleteLenderProfile = () => {
  const routes = [
    {
      name: 'Complete Lender Profile',
      path: '/app/lend-money/complete-lender-profile',
    },
  ];
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const sigPadRef = useRef(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [NINFront, setNationalIDFront] = useState();
  const [NINBack, setNationalIDBack] = useState();

  const createLenderProfileMutation = useCreateLenderProfile();

  const clear = () => {
    sigPadRef.current.clear();
  };

  const trim = () => {
    const trimmedData = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
    setTrimmedDataURL(trimmedData);
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleStepClick = (index) => {
    setCurrentStep(index);
  };

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setFile(file);
    } else {
      alert('Please upload a valid image file.');
    }
  };

  return (
    <ContentLayout>
      <Breadcrumbs routes={routes} />
      <div className="p-4">
        <Form
          id="create-post"
          onSubmit={async (values) => {
            const bodyFormData = new FormData();
            bodyFormData.append('nationalIDFront', NINFront);
            bodyFormData.append('nationalIDBack', NINBack);
            bodyFormData.append('pastLendingExperience', values.pastLendingExperience);
            bodyFormData.append('sourcesOfFunds', values.sourcesOfFunds);
            bodyFormData.append('collateralCategory', values.collateralCategory);
            bodyFormData.append('lendingAmountMin', values.lendingAmountMin);
            bodyFormData.append('lendingAmountMax', values.lendingAmountMax);
            bodyFormData.append('lendingDurationMin', values.lendingDurationMin);
            bodyFormData.append('lendingDurationMax', values.lendingDurationMax);
            bodyFormData.append('interestRateMin', values.interestRateMin);
            bodyFormData.append('interestRateMax', values.interestRateMax);
            bodyFormData.append('signature', trimmedDataURL);
            bodyFormData.append('acceptLenderTermsAndConditions', acceptTerms);
            bodyFormData.append('signatureConfirmed', true);

            await createLenderProfileMutation.mutateAsync({ data: bodyFormData });
            Swal.fire({
              title: 'Success!',
              text: 'Thank you for your submission. You will be notified once your lender profile has been activated.',
              icon: 'success',
              confirmButtonText: 'Ok',
            }).then(() => {
              navigate('/app');
            });
          }}
        >
          {({ register, formState, watch, setValue }) => (
            <>
              <Stepper
                steps={[
                  {
                    title: 'Identification Document',
                    content: (
                      <div className="">
                        <h2 className="text-lg md:text-2xl font-bold mb-6 text-center">
                          Upload Identification Documents
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          <div className="flex flex-col items-center">
                            <div className="mb-3 w-full">
                              <label className="text-sm font-medium text-gray-700">
                                Upload National ID / Passport (Front Face)
                              </label>
                              <input
                                type="file"
                                accept="image/*"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                onChange={(e) => handleFileChange(e, setNationalIDFront)}
                              />
                            </div>
                            {/* {NINFront && (
                              <div className="mt-3">
                                <img
                                  src={URL.createObjectURL(NINFront)}
                                  alt="National ID Front"
                                  className="h-40 w-auto rounded-md shadow-sm"
                                />
                              </div>
                            )} */}
                          </div>
                          <div className="flex flex-col items-center">
                            <div className="mb-3 w-full">
                              <label className="text-sm font-medium text-gray-700">
                                Upload National ID / Passport (Back Face)
                              </label>
                              <input
                                type="file"
                                accept="image/*"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                onChange={(e) => handleFileChange(e, setNationalIDBack)}
                              />
                            </div>
                            {/* {NINBack && (
                              <div className="mt-3">
                                <img
                                  src={URL.createObjectURL(NINBack)}
                                  alt="National ID Back"
                                  className="h-40 w-auto rounded-md shadow-sm"
                                />
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: 'Lending Preferences',
                    content: (
                      <>
                        <h2 className="text-lg md:text-2xl font-bold mb-6 text-center">
                          Lending Preferences
                        </h2>
                        <div className="mx-0 md:mx-12 space-y-4 md:space-y-12">
                          <div className="">
                            <InputField
                              type="number"
                              label="Past Lending Experience (In Years)"
                              registration={register('pastLendingExperience')}
                            />
                            <ReactMultiSelectField
                              label="Sources of Funds"
                              registration={register('sourcesOfFunds')}
                              editorContent={watch('sourcesOfFunds')}
                              setValue={setValue}
                              isMulti={true}
                              name="sourcesOfFunds"
                              defaultValue={watch('sourcesOfFunds')}
                              options={[
                                { label: 'Salary', value: 'salary' },
                                { label: 'Business', value: 'business' },
                                { label: 'Investment', value: 'investment' },
                                { label: 'Others', value: 'others' },
                              ]}
                            />
                          </div>
                          <div className="">
                            <div>How much are you comfortable lending?</div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              <InputField
                                type="number"
                                label="Min (UGX)"
                                registration={register('lendingAmountMin')}
                              />
                              <InputField
                                type="number"
                                label="Max (UGX)"
                                registration={register('lendingAmountMax')}
                              />
                            </div>
                          </div>
                          <div>
                            <div>How long would you be comfortable lending for?</div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              <InputField
                                type="number"
                                label="Min (Months)"
                                registration={register('lendingDurationMin')}
                              />
                              <InputField
                                type="number"
                                label="Max (Months)"
                                registration={register('lendingDurationMax')}
                              />
                            </div>
                          </div>
                          <div>
                            <div>What interest rate would you be comfortable lending at?</div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              <InputField
                                type="number"
                                label="Min (%)"
                                registration={register('interestRateMin')}
                              />
                              <InputField
                                type="number"
                                label="Max (%)"
                                registration={register('interestRateMax')}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: 'Terms and Conditions',
                    content: (
                      <>
                        <h2 className="text-lg md:text-2xl font-bold mb-6 text-center">
                          Terms and Conditions
                        </h2>
                        <div
                          className="rounded-xl border border-gray-200 mb-4"
                          style={{ height: '300px', overflow: 'hidden', overflowY: 'scroll' }}
                        >
                          <div className="bg-secondary-100 p-2 rounded-t-xl text-white">
                            Terms and Conditions (Please read the terms and conditions below before)
                          </div>
                          <div className="p-4">
                            {/* Terms and conditions content */}
                            <div className="mb-2 text-sm md:text-md">
                              <b>1. Introduction:</b> These terms govern the lending activities
                              conducted through the Finablr platform operated by Enabling Finance
                              Limited.
                            </div>
                            <div className="mb-2 text-sm md:text-md">
                              <b>2. Legal and Regulatory Information:</b> EFL is registered in
                              Uganda and authorized by the Uganda Microfinance Regulatory Authority.
                              The company manages the platform connecting lenders and borrowers,
                              oversees loan agreements, and handles repayments.
                            </div>
                            {/* Additional terms here... */}
                            <div className="mb-2 text-sm md:text-md">
                              By checking the box below, you are in agreement to the Enabling
                              Finance Limited lender terms and conditions.
                            </div>
                          </div>
                          <div className="m-2">
                            <CheckboxField
                              label="Accept Terms and Conditions"
                              registration={register('acceptTerms')}
                              onChange={(e) => setAcceptTerms(e.target.checked)}
                            />
                          </div>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: 'Digital Signature',
                    content: (
                      <div>
                        <h2 className="text-lg md:text-2xl font-bold mb-6 text-center">
                          Digital Signature
                        </h2>
                        <div className="mb-4">
                          Before you complete your lender profile, you're required to submit your
                          digital signature. Please sign in the box below.
                        </div>
                        <div className="border border-gray-200 rounded-2xl shadow bg-white mb-4 p-4">
                          <div>
                            {trimmedDataURL ? (
                              <img alt="signature" src={trimmedDataURL} className="mx-auto" />
                            ) : (
                              <SignatureCanvas
                                penColor="darkblue"
                                canvasProps={{
                                  // width: 200,
                                  // height: 200,
                                  className:
                                    'sigCanvas w-[60vw] border border-gray-200 rounded-2xl shadow bg-white mb-4',
                                }}
                                ref={sigPadRef}
                              />
                            )}
                          </div>
                          {!trimmedDataURL ? (
                            <div className="flex flex-row items-center space-x-2">
                              <Button size="sm" type="button" variant="outline" onClick={clear}>
                                Clear
                              </Button>
                              <button type="button" size="sm" onClick={trim}>
                                Confirm Signature
                              </button>
                            </div>
                          ) : (
                            <div className="mt-8 flex flex-col items-center justify-center">
                              <div className="mb-4 text-xs bg-red-100 text-red-500 p-3 rounded-2xl shadow">
                                By continuing from this juncture, you affirm that the aforementioned
                                signature is attributed to you and will be utilized for the purpose
                                of signing your contractual agreements.
                              </div>

                              <div className="mt-8 flex flex-row items-center space-x-2 text-xs text-gray-500">
                                <div className="">
                                  <MdWarning />
                                </div>
                                <div className="">
                                  Supported by the Uganda Electronic Signatures Act 2011
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ),
                  },
                ]}
                currentStep={currentStep}
                onStepClick={handleStepClick}
              />
              <div className="flex flex-row justify-between">
                <Button
                  disabled={currentStep === 0}
                  onClick={handleBack}
                  variant="secondary"
                  className="w-1/3"
                >
                  Back
                </Button>
                {trimmedDataURL ? (
                  <Button size="sm" type="submit" isLoading={createLenderProfileMutation.isLoading}>
                    Submit Lender Profile
                  </Button>
                ) : (
                  <Button
                    onClick={handleNext}
                    disabled={currentStep === 2 && !acceptTerms}
                    className="w-1/3"
                  >
                    Next
                  </Button>
                )}
              </div>
            </>
          )}
        </Form>
      </div>
    </ContentLayout>
  );
};
