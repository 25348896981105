import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button, Spinner } from 'components/Elements';
import { Link, useNavigate, useParams } from 'react-router-dom';
import UserImg from 'assets/user.png';
import { Download, Eye, Heart } from 'react-feather';
import { useLoan } from '../api/getLoan';
import { UPLOADS_URL } from 'config';
import { FaStar } from 'react-icons/fa';
import { LendNow } from '../components/LendNow';
import { CounterOffer } from '../components/CounterOffer';
import { useAuth } from 'lib/auth';
import { VerifyPhoneNumber } from '../components/VerifyPhoneNumber';
import { AddToWishlist } from 'features/wishlist';
import { formatText } from 'utils/formatText';
import { PaymentSchedule } from 'features/pigeonLoans';
import { splitAndCapitalize } from 'utils/splitAndCapitalize';

export const LoanRequest = () => {
  const { id } = useParams();

  const loanQuery = useLoan({ id });
  const navigate = useNavigate();

  const { user } = useAuth();
  const routes = [
    {
      name: 'Lend Money',
      path: '/app/lend-money',
    },
    {
      name: ` ${loanQuery.data?.client?.basicInformation?.firstName}`,
      path: `/app/lend-money/${id}`,
    },
  ];

  if (loanQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!loanQuery.data) return null;

  const userHasMadeOffer = loanQuery.data?.offers?.some((offer) => offer.user === user._id);

  return (
    <ContentLayout title="Loan details" end={<AddToWishlist loanId={loanQuery.data?._id} />}>
      <Breadcrumbs routes={routes} />
      <div className="mt-8">
        <div className="bg-white rounded-2xl shadow-md p-6 flex flex-wrap justify-around items-center mb-6">
          {/* Client Information */}
          <div className="flex flex-col items-center text-center mb-4 md:mb-0">
            <div className="text-gray-600 font-semibold">Name</div>
            <div className="text-xl font-bold">
              {loanQuery.data?.client?.authentication?.username}
            </div>
            <div className="flex flex-row items-center justify-center mt-2">
              <FaStar className="text-yellow-500" />
              <span className="ml-2 text-lg font-semibold">
                {loanQuery.data?.client?.feedbackAndReviews?.userRatings || 4.5}
              </span>
            </div>
          </div>

          {/* Loan Principal */}
          <div className="flex flex-col items-center text-center mb-4 md:mb-0">
            <div className="text-gray-600 font-semibold">Principal</div>
            <div className="text-2xl font-bold text-green-600 mb-3">
              UGX {loanQuery.data?.amount?.loanRequired?.toLocaleString()}
            </div>
            <PaymentSchedule
              amount={loanQuery.data?.amount?.loanRequired}
              interestRate={loanQuery.data?.interestRate}
              term={loanQuery.data?.term}
              monthlyPayment={loanQuery.data?.monthlyPayment}
              paymentMethod={loanQuery.data?.paymentMethod}
            />
          </div>

          {/* Loan Period and Monthly Payment */}
          <div className="flex flex-col items-center text-center mb-4 md:mb-0">
            <div className="text-gray-600 font-semibold">Period</div>
            <div className="text-xl font-bold">{loanQuery.data?.term} months</div>
            <div className="text-gray-600 font-semibold mt-2">Monthly Payment</div>
            <div className="text-2xl font-bold text-blue-600">
              UGX {loanQuery.data?.monthlyPayment?.toLocaleString()}
            </div>
          </div>

          {/* Interest Details */}
          <div className="flex flex-col items-center text-center">
            <div className="text-gray-600 font-semibold">Interest</div>
            <div className="text-2xl font-bold text-red-600">
              UGX {loanQuery.data?.interestCharged?.toLocaleString()}
            </div>
            <div className="text-gray-600 font-semibold mt-2">
              {loanQuery.data?.interestRate}% per month
            </div>
            <div className="bg-gray-100 rounded-full py-1 px-3 mt-3 text-gray-500 text-xs">
              {formatText(loanQuery.data?.paymentMethod)}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-2xl shadow-md mb-4">
          <div className="p-4 bg-blue-100 rounded-t-2xl flex flex-row items-center justify-between">
            <div className="">Loan Purpose</div>
            {/* <div className="uppercase text-secondary-100">Business</div> */}
          </div>
          <div className="p-4">{loanQuery.data?.purpose}</div>
        </div>
        <div className="bg-white rounded-2xl shadow-md mb-4">
          {loanQuery.data?.duedeligence && (
            <div className="p-4 bg-blue-100 rounded-t-2xl flex flex-row items-center justify-between">
              <div className="">Collateral Assets</div>
              <div className="">
                <div className="grid grid-cols-2 gap-4 items-center">
                  <div className="text-sm">Collateral Market Value</div>
                  <div className="text-gray-900 text-end font-bold">
                    UGX{' '}
                    {loanQuery.data?.duedeligence?.valuation?.collateral_market_value?.toLocaleString()}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-2 gap-4 items-center">
                  <div className="text-sm">EFL Approved Loan Max Value</div>{' '}
                  <div className="text-gray-900 text-end font-bold">
                    {' '}
                    UGX{' '}
                    {loanQuery.data?.duedeligence?.valuation?.approved_loan_max_value?.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          )}
          {loanQuery.data?.collateral?.map((collateral) => {
            if (collateral.real_estate) {
              return (
                <div className="p-4 mb-4" key={collateral._id}>
                  <div className="flex flex-row items-center justify-between">
                    <div className="">
                      <div className="">Title tenure: {collateral?.real_estate?.type_of_title}</div>
                      <div className="">Size: {collateral?.real_estate?.size_of_land}</div>
                      <div className="">Location: {collateral?.real_estate?.land_location}</div>
                      <div className="">
                        What's on land: {formatText(collateral?.real_estate?.whats_on_land)}
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <span>Market Value</span>
                        <br />
                        <span className="text-gray-900 font-semibold">
                          UGX {collateral?.current_worth?.toLocaleString()}
                        </span>
                      </div>
                      <div className="">
                        <span>EFL Loan Cap</span>
                        <br />
                        <span className="text-gray-900 font-semibold">
                          UGX{' '}
                          {loanQuery.data?.duedeligence?.valuation?.approved_loan_max_value?.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }

            if (collateral.vehicles) {
              return (
                <div className="p-4 mb-4" key={collateral._id}>
                  <div className="flex flex-row items-center justify-between">
                    <div className="">
                      <div className="">Brand: {collateral?.vehicles?.brand}</div>
                      <div className="">Color: {collateral?.vehicles?.color}</div>
                      <div className="">Mileage: {collateral?.vehicles?.mileage}</div>
                      <div className="">Model:{collateral?.vehicles?.model}</div>
                      <div className="">Parking Status:{collateral?.vehicles?.parking_status}</div>
                    </div>
                    <div className="">
                      <div className="">
                        <span>Market Value</span>
                        <br />
                        <span className="text-gray-900 font-semibold">
                          UGX {collateral?.current_worth?.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {collateral.pictures?.map((picture, index) => (
                      <div
                        key={index}
                        className="relative overflow-hidden bg-white rounded-lg shadow-md"
                      >
                        <img
                          src={`${UPLOADS_URL}/collateral/${picture}`}
                          alt={`Picture ${index + 1}`}
                          className="w-full h-64 object-cover transition-transform duration-300 transform hover:scale-105"
                        />
                        <div className="absolute top-0 left-0 bg-black bg-opacity-50 text-white py-1 px-3 text-sm font-semibold">
                          Picture {index + 1}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            }

            if (collateral.computers) {
              return (
                <div className="p-4 mb-4" key={collateral._id}>
                  <div className="flex flex-row items-center justify-between">
                    <div className="">
                      <div className="">Computer Brand: {collateral?.computers?.brand}</div>
                      <div className="">RAM Size: {collateral?.computers?.ram_size}</div>
                      <div className="">
                        Hard Disk Space: {collateral?.computers?.hard_disk_space}
                      </div>
                      <div className="">
                        Processor Speed: {collateral?.computers?.processor_speed}
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <span>Market Value</span>
                        <br />
                        <span className="text-gray-900 font-semibold">
                          UGX {collateral?.current_worth?.toLocaleString()}
                        </span>
                      </div>
                      <div className="">
                        <span>EFL Loan Cap</span>
                        <br />
                        <span className="text-gray-900 font-semibold">
                          UGX{' '}
                          {loanQuery.data?.duedeligence?.valuation?.approved_loan_max_value?.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}

          <div className="grid grid-cols-3 gap-8 p-6">
            {loanQuery.data?.collateral?.map((collateral, index) => {
              if (collateral.vehicles) {
                return (
                  <>
                    <div className="">
                      {collateral.vehicles?.brand} {collateral.vehicles?.model} -{' '}
                      {collateral.vehicles?.year_of_manufacture}
                    </div>
                    <div className="">
                      <div className="text-gray-600 text-sm">Value</div>
                      <div className="font-semibold text-md col-span-2">
                        UGX {collateral?.current_worth?.toLocaleString()}
                      </div>
                    </div>
                  </>
                );
              }
              if (collateral?.real_estate) {
                return (
                  <div className="grid grid-cols-2 gap-4 w-full">
                    <div className="">
                      Title Tenre: {collateral?.title}
                      <div className="">
                        Location:{' '}
                        <span className="font-semibold">
                          {collateral.real_estate?.land_location}
                        </span>
                      </div>
                      <div className="">
                        Size of Land:{' '}
                        <span className="font-semibold">
                          {splitAndCapitalize(collateral.real_estate?.size_of_land)}
                        </span>
                      </div>
                      <div className="">
                        What's on land:{' '}
                        <span className="font-semibold">
                          {splitAndCapitalize(collateral.real_estate?.whats_on_land)}
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <div className="text-gray-600 text-sm">Value</div>
                      <div className="font-semibold text-md col-span-2">
                        UGX {collateral?.current_worth?.toLocaleString()}
                      </div>
                      <div className="text-gray-600 text-sm">Assessment Fees</div>
                      <div className="font-semibold text-md col-span-2">
                        UGX {collateral?.total_assessment_fees?.toLocaleString()}
                      </div>
                    </div>
                  </div>
                );
              }
              if (collateral?.computers) {
                return (
                  <div className="flex flex-row items-center gap-4 w-full">
                    <div className="">
                      {collateral.computers?.specify_others &&
                      collateral.computers?.specify_others !== 'undefined' ? (
                        <div className="flex flex-row items-center space-x-2">
                          <div className="text-gray-600 text-sm">Brand:</div>
                          <div className="font-semibold">
                            {collateral.computers?.specify_others}
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-row items-center space-x-2">
                          <div className="text-gray-600 text-sm">Brand:</div>
                          <div className="font-semibold">{collateral.computers?.brand}</div>
                        </div>
                      )}
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Hard Disk Space: </div>
                        <div className="font-semibold">
                          {collateral?.computers?.hard_disk_space} GB
                        </div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Processor Speed: </div>
                        <div className="font-semibold">
                          {collateral.computers?.processor_speed} GHz
                        </div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">RAM Size: </div>
                        <div className="font-semibold">{collateral.computers?.ram_size} GB</div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Collateral Value:</div>
                        <div className="font-semibold text-md col-span-2">
                          UGX {collateral?.current_worth?.toLocaleString()}
                        </div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Assessment Fees:</div>
                        <div className="font-semibold text-md col-span-2">
                          UGX {collateral?.total_assessment_fees?.toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (collateral?.smart_phone) {
                return (
                  <div className="flex flex-row items-center gap-4 w-full">
                    <div className="">
                      {collateral.smart_phone?.specify_others &&
                      collateral.smart_phone?.specify_others !== 'undefined' ? (
                        <div className="flex flex-row items-center space-x-2">
                          <div className="text-gray-600 text-sm">Brand:</div>
                          <div className="font-semibold">
                            {collateral.smart_phone?.specify_others}
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-row items-center space-x-2">
                          <div className="text-gray-600 text-sm">Brand:</div>
                          <div className="font-semibold">{collateral.smart_phone?.brand}</div>
                        </div>
                      )}
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">RAM Size: </div>
                        <div className="font-semibold">{collateral?.smart_phone?.ram_size} GB</div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Storage Space: </div>
                        <div className="font-semibold">
                          {collateral.smart_phone?.storage_space} GB
                        </div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Model: </div>
                        <div className="font-semibold">{collateral.smart_phone?.model}</div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Collateral Value:</div>
                        <div className="font-semibold text-md col-span-2">
                          UGX {collateral?.current_worth?.toLocaleString()}
                        </div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Assessment Fees:</div>
                        <div className="font-semibold text-md col-span-2">
                          UGX {collateral?.total_assessment_fees?.toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (collateral?.other_electronics) {
                return (
                  <div className="flex flex-row items-center gap-4 w-full">
                    <div className="">
                      {collateral.other_electronics?.specify_others &&
                      collateral.other_electronics?.specify_others !== 'undefined' ? (
                        <div className="flex flex-row items-center space-x-2">
                          <div className="text-gray-600 text-sm">Brand:</div>
                          <div className="font-semibold">
                            {collateral.other_electronics?.specify_others}
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-row items-center space-x-2">
                          <div className="text-gray-600 text-sm">Brand:</div>
                          <div className="font-semibold">{collateral.other_electronics?.brand}</div>
                        </div>
                      )}
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Nature of Electronic:</div>
                        <div className="font-semibold">
                          {collateral.other_electronics?.nature_of_electronic}
                        </div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Model: </div>
                        <div className="font-semibold">
                          {collateral.other_electronics?.model_type}
                        </div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Collateral Value:</div>
                        <div className="font-semibold text-md col-span-2">
                          UGX {collateral?.current_worth?.toLocaleString()}
                        </div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">Assessment Fees:</div>
                        <div className="font-semibold text-md col-span-2">
                          UGX {collateral?.total_assessment_fees?.toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <hr />
        </div>

        {loanQuery.data?.collateral &&
          loanQuery.data?.collateral?.map((col, index) => (
            <div className="bg-white rounded-2xl shadow mb-4 p-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {col?.pictures?.map((picture, index) => (
                  <div
                    key={index}
                    className="relative overflow-hidden bg-white rounded-lg shadow-md"
                  >
                    <img
                      src={`${UPLOADS_URL}/collateral/${picture}`}
                      alt={`Picture ${index + 1}`}
                      className="w-full h-64 object-cover transition-transform duration-300 transform hover:scale-105"
                    />
                    <div className="absolute top-0 left-0 bg-black bg-opacity-50 text-white py-1 px-3 text-sm font-semibold">
                      Picture {index + 1}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}

        {loanQuery.data?.status === 'CC_APPROVED' && (
          <>
            {!userHasMadeOffer && (
              <div className="mb-4 flex flex-row justify-end">
                {user.basicInformation?.contactInformation?.verifyPhoneNumber ? (
                  <>
                    <div className="flex flex-row items-center space-x-2">
                      <LendNow
                        id={loanQuery.data?._id}
                        amount={loanQuery.data?.amount?.loanRequired}
                        term={loanQuery.data?.term}
                        interest={loanQuery.data?.interestRate}
                        monthlyPayment={loanQuery.data?.monthlyPayment}
                      />
                      <CounterOffer
                        id={loanQuery.data?._id}
                        loanAmount={loanQuery.data?.amount?.loanRequired}
                        loanMonthlyPayment={loanQuery.data?.monthlyPayment}
                        loanTerm={loanQuery.data?.term}
                        loanInterestRate={loanQuery.data?.interestRate}
                      />
                    </div>
                  </>
                ) : (
                  <VerifyPhoneNumber />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </ContentLayout>
  );
};
