import { Button, Dialog, DialogTitle } from 'components/Elements';
import React from 'react';
import { MdWarning } from 'react-icons/md';
import { Form, InputField } from 'components/Form';
import { useUpdateAddress } from '../api/updateAddress';

export function UpdateAddress() {
  const updateAddressMutation = useUpdateAddress();

  const [show, setShow] = React.useState(false);

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div>
      <button className="underline text-red-500" onClick={handleOpen}>
        [ Click To Provide Your Address Details ]
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            Address Details Required
          </DialogTitle>
          <>
            <div className="mb-3">
              Please note that your address details are required to have a valid contract.
            </div>
            <Form
              id="update-permanent-address"
              onSubmit={async (values) => {
                await updateAddressMutation.mutateAsync({ data: values });
              }}
            >
              {({ register, formState }) => (
                <>
                  <InputField
                    label="Residential Address"
                    error={formState.errors['residentialAddress']}
                    registration={register('residentialAddress')}
                  />
                  <InputField
                    label="Permanent Address"
                    error={formState.errors['permanentAddress']}
                    registration={register('permanentAddress')}
                  />
                  <div className="justify-center flex flex-row items-center space-x-2">
                    <Button variant="inverse" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button>Submit</Button>
                  </div>
                </>
              )}
            </Form>
          </>
        </div>
      </Dialog>
    </div>
  );
}
